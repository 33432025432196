import {useState, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './style.module.css';
import FormButtons from './FormButtons';
import TimePicker from './TimePickerComponent/TimePicker';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Form = ({
  cardID,
  cardItem,
  formData,
  cardDetails,
  handleInputChange,
  handleDateChange,
  handleTimeInputChange,
  handleImageChange,
  path,
}) => {
  // const [disabled, setDisabled] = useState(true);
  const [isClicked, setIsClicked] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const fileInputRef = useRef(null);

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const navigate = useNavigate('');
  const handleClick = () => {
    setIsClicked(navigate(path));
  };
console.log(cardDetails);
  return (
    <form className={styles.form} action="">
      {/* Groom Name */}
      {cardItem.groomName_yorn && (
        <input
          type="text"
          name="groomName"
          id="text_groom"
          className={styles.input}
          placeholder="Groom name"
          onChange={handleInputChange}
          defaultValue={cardDetails ? cardDetails.groomName : ''}
        />
      )}
      {/* Groom Surname */}
      {cardItem.groomSurname_yorn && (
        <input
          type="text"
          name="groomSurname"
          id="text_groomSurname"
          className={styles.input}
          placeholder="Groom surname"
          onChange={handleInputChange}
          defaultValue={cardDetails ? cardDetails.groomSurname : ''}
        />
      )}
      {/* Bride Name */}
      {cardItem.brideName_yorn && (
        <input
          type="text"
          name="brideName"
          id="text_bride"
          className={styles.input}
          placeholder="Bride Name"
          onChange={handleInputChange}
          defaultValue={cardDetails ? cardDetails.brideName : ''}
        />
      )}
      {/* Bride Surname */}
      {cardItem.brideSurname_yorn && (
        <input
          type="text"
          name="brideSurname"
          id="text_brideSurname"
          className={styles.input}
          placeholder="Bride surname"
          onChange={handleInputChange}
          defaultValue={cardDetails ? cardDetails.brideSurname : ''}
        />
      )}
      {/* Father Name */}
      {cardItem.fatherName_yorn && (
        <input
          type="text"
          name="fatherName"
          id="text_father"
          className={styles.input}
          placeholder="Father name"
          onChange={handleInputChange}
          defaultValue={cardDetails ? cardDetails.fatherName : ''}
        />
      )}
      {/* Mother Name */}
      {cardItem.motherName_yorn && (
        <input
          type="text"
          name="motherName"
          id="text_mother"
          className={styles.input}
          placeholder="Mother name"
          onChange={handleInputChange}
          defaultValue={cardDetails ? cardDetails.motherName : ''}
        />
      )}
      {/* Husband Name */}
      {cardItem.husbandName_yorn && (
        <input
          type="text"
          name="husbandName"
          id="text_husband"
          className={styles.input}
          placeholder="Husband name"
          onChange={handleInputChange}
          defaultValue={cardDetails ? cardDetails.husbandName : ''}
        />
      )}
      {/* Wife Name */}
      {cardItem.wifeName_yorn && (
        <input
          type="text"
          name="wifeName"
          id="text_wife"
          className={styles.input}
          placeholder="Wife name"
          onChange={handleInputChange}
          defaultValue={cardDetails ? cardDetails.wifeName : ''}
        />
      )}
      {/* Person Name */}
      {cardItem.personName_yorn && (
        <input
          type="text"
          name="personName"
          id="text_person"
          className={styles.input}
          placeholder="Name"
          onChange={handleInputChange}
          defaultValue={cardDetails ? cardDetails.personName : ''}
        />
      )}
      {/* Person Age */}
      {cardItem.age_yorn && (
        <input
          type="text"
          name="personAge"
          id="text_age"
          className={styles.input}
          placeholder="Age"
          onChange={handleInputChange}
          defaultValue={cardDetails ? cardDetails.age : ''}
        />
      )}
      {/* Input Unique */}
      {cardItem.title_yorn && (
        <input
          type="text"
          name="title"
          id="text_title"
          className={styles.input}
          placeholder={cardItem.placeholder}
          onChange={handleInputChange}
          defaultValue={cardDetails ? cardDetails.title : ''}
        />
      )}
      {/* Place */}
      {cardItem.place_yorn && (
        <div className={styles.input_block}>
          <input
            type="text"
            name="place"
            id="text_place"
            className={styles.input}
            placeholder={cardItem.placePlaceholder}
            onChange={handleInputChange}
            defaultValue={cardDetails ? cardDetails.place : ''}
          />
          {cardItem.placeTime_yorn && (
            <TimePicker
              name="placeTime"
              handleTimeInputChange={handleTimeInputChange}
              defaultValue={cardDetails ? cardDetails.placeTime : ''}
            />
          )}
        </div>
      )}
      {/* Restaurant Name */}
      {cardItem.restaurantName_yorn && (
        <div className={styles.input_block}>
          <input
            type="text"
            name="restaurantName"
            id="text_rest"
            className={styles.input}
            placeholder="Restaurant"
            onChange={handleInputChange}
            defaultValue={cardDetails ? cardDetails.restaurantName : ''}
          />
          {cardItem.restaurantTime_yorn && (
            <TimePicker
              name="restaurantTime"
              handleTimeInputChange={handleTimeInputChange}
              defaultValue={cardDetails ? cardDetails.restaurantTime : ''}
            />
          )}
        </div>
      )}
      {/* Restaurant Address */}
      {cardItem.restaurant_address_yorn && (
        <div className={styles.input_block}>
          <input
            type="text"
            name="restaurantAddress"
            id="text_rest"
            className={styles.input}
            placeholder="Restaurant Address"
            onChange={handleInputChange}
            defaultValue={cardDetails ? cardDetails.restaurantAddress : ''}
          />
          {cardItem.restaurantAddressTime_yorn && (
            <TimePicker
              name="restaurantTime"
              handleTimeInputChange={handleTimeInputChange}
              defaultValue={cardDetails ? cardDetails.restaurantTime : ''}
            />
          )}
        </div>
      )}
      {/* Church Name */}
      {cardItem.churchName_yorn && (
        <div className={styles.input_block}>
          <input
            type="text"
            name="churchName"
            id="text_church_name"
            className={styles.input}
            placeholder="Church"
            onChange={handleInputChange}
            defaultValue={cardDetails ? cardDetails.churchName : ''}
          />
          {cardItem.churchTime_yorn && (
            <TimePicker
              name="churchTime"
              handleTimeInputChange={handleTimeInputChange}
              defaultValue={cardDetails ? cardDetails.churchTime : ''}
            />
          )}
        </div>
      )}
      {/* Church Address */}
      {cardItem.church_address_yorn && (
        <div className={styles.input_block}>
          <input
            type="text"
            name="churchAddress"
            id="text_church_address"
            className={styles.input}
            placeholder="Church"
            onChange={handleInputChange}
            defaultValue={cardDetails ? cardDetails.churchAddress : ''}
          />
          {cardItem.churchTimeAddress_yorn && (
            <TimePicker
              name="churchTime"
              handleTimeInputChange={handleTimeInputChange}
              defaultValue={cardDetails ? cardDetails.churchTime : ''}
            />
          )}
        </div>
      )}
      {/* Address */}
      {cardItem.address_yorn && (
        <div className={styles.input_block}>
          <input
            type="text"
            name="address"
            id="text_address"
            className={styles.input}
            placeholder="Address"
            onChange={handleInputChange}
            defaultValue={cardDetails ? cardDetails.address : ''}
          />
          {cardItem.addressTime_yorn && (
            <TimePicker
              name="addressTime"
              handleTimeInputChange={handleTimeInputChange}
              defaultValue={cardDetails ? cardDetails.addressTime : ''}
            />
          )}
        </div>
      )}
      {/* Years Anniversary*/}
      {cardItem.yearsAnniversary_yorn && (
        <input
          type="text"
          name="yearsAnniversary"
          id="text_yearsAnniversary"
          className={styles.input}
          placeholder="Years of Anniversary"
          onChange={handleInputChange}
          defaultValue={cardDetails ? cardDetails.yearsAnniversary : ''}
        ></input>
      )}
      <DatePicker
        type="date"
        name="date"
        id="date"
        selected={selectedDate}
        onChange={(date) => {
          handleDateChange(date);
          setSelectedDate(date);
        }}
        defaultValue={cardDetails ? cardDetails.date : ''}
        dateFormat="dd/MM/yyyy"
        placeholderText="dd/mm/yyyy"
        className={styles.input}
        calendarClassName={styles.custom_calendar}
        toggleCalendarOnIconClick
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M1 7.66667H21M5.44444 1V3.22222M16.5556 1V3.22222M4.33333 12.1111H6.55555M4.33333 16.5556H6.55555M9.88889 12.1111H12.1111M9.88889 16.5556H12.1111M15.4444 12.1111H17.6667M15.4444 16.5556H17.6667M4.55555 21H17.4444C18.689 21 19.3113 21 19.7867 20.7578C20.2048 20.5448 20.5448 20.2048 20.7578 19.7867C21 19.3113 21 18.689 21 17.4444V6.77778C21 5.53321 21 4.91093 20.7578 4.43558C20.5448 4.01743 20.2048 3.67748 19.7867 3.46443C19.3113 3.22222 18.689 3.22222 17.4444 3.22222H4.55555C3.311 3.22222 2.68871 3.22222 2.21336 3.46443C1.79521 3.67748 1.45526 4.01743 1.24221 4.43558C1 4.91093 1 5.53321 1 6.77778V17.4444C1 18.689 1 19.3113 1.24221 19.7867C1.45526 20.2048 1.79521 20.5448 2.21336 20.7578C2.68871 21 3.31099 21 4.55555 21Z"
              stroke="#4C4C4C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        showIcon
      />
      {/* Email */}
      {cardItem.email_yorn && (
        <input
          type="email"
          name="email"
          id="email"
          className={styles.input}
          placeholder="Email"
          onChange={handleInputChange}
          defaultValue={cardDetails ? cardDetails.email : ''}
        ></input>
      )}
      {/* Phone */}
      {cardItem.phone_yorn && (
        <div className={styles.tel_block}>
          <div className={styles.tel_text}>+374</div>
          <input
            className={`${styles.tel} ${styles.input}`}
            type="text"
            name="phone"
            id="tel"
            placeholder="Phone number"
            onChange={handleInputChange}
            defaultValue={cardDetails ? cardDetails.phone : ''}
          />
        </div>
      )}
      {/* Only Time */}
      {cardItem.time_yorn && (
        <TimePicker
          name="time"
          handleTimeInputChange={handleTimeInputChange}
          defaultValue={cardDetails ? cardDetails.time : ''}
        />
      )}
      {cardItem.uploadImg_yorn && (
        <div className={styles.upload_block}>
          <div className={styles.parent_elem}>
            <input
              ref={fileInputRef}
              type="file"
              name="file"
              id="file"
              className={styles.input}
              placeholder="Choose image for upload"
              onChange={(e) => handleImageChange(e)}
              defaultValue={cardDetails ? cardDetails.file : ''}
              style={{opacity: '0'}}
            />
            <div
              className={`${styles.input} ${styles.image_upload}`}
              // onClick={handleUploadButtonClick}
            >
              Choose image for upload
            </div>
          </div>
          <button onClick={handleUploadButtonClick} type="button">
            Browse file
          </button>
        </div>
      )}

      {/* <input
        type="email"
        name="email"
        id="time"
        className={styles.input}
        placeholder="Email"
        onChange={handleInputChange}
      /> */}
      {/* <p className={styles.text}>
        Print your card for <span>1000</span> AMD each! Print 40 or more, get
        the online card <span>FREE</span>.
      </p> */}
      {/* <div className={styles.input_bottom_block}>
        <div className={styles.check_block}>
          <input
            onChange={toggleDisabled}
            type="checkbox"
            name="print_box"
            id="checkbox"
            className={styles.checkbox}
          />
          <label htmlFor="checkbox">Print</label>
        </div>
        <div className={styles.tel_block}>
          <div className={styles.tel_text}>+374</div>
          <input
            className={`${styles.tel} ${styles.input}`}
            type="text"
            name="phone"
            id="tel"
            disabled={disabled ? 'disabled' : null}
            placeholder="Phone number"
            style={!disabled ? {background: 'unset'} : null}
            onChange={handleInputChange}
          />
        </div>
        <input
          type="text"
          name="quantity"
          id="quantity"
          className={`${styles.input} ${styles.quantity}`}
          placeholder="Quantity"
          disabled={disabled ? 'disabled' : null}
          style={!disabled ? {background: 'unset'} : null}
          onChange={handleInputChange}
        />
      </div> */}
      <div className={styles.btns_block}>
        <FormButtons
          text="preview"
          formData={formData}
          cardDetails={cardDetails}
          isStaylesPrev={true}
          path={`/card/${cardID}`}
        />
        <FormButtons
          text="continue"
          formData={{}}
          isStaylesCont={true}
          onClick={isClicked ? handleClick() : ''}
          path={`/card/payment/${cardID}`}
        />
      </div>
    </form>
  );
};

export default Form;
