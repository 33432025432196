// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__46kyE {
  background-size: cover;
  width: 924px;
  height: 574px;
  background-repeat: no-repeat;
}
.style_inner__nBQAS {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 73px;
  padding: 188px 0;
}
.style_title__hF1gC {
  color: #466355;
  font-family: "Crimson Text";
  font-size: 128px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/components/CardsComponents/ThankYouCards/ThankYouThird/style.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,4BAA4B;AAC9B;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,2BAA2B;EAC3B,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".wrapper {\n  background-size: cover;\n  width: 924px;\n  height: 574px;\n  background-repeat: no-repeat;\n}\n.inner {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  row-gap: 73px;\n  padding: 188px 0;\n}\n.title {\n  color: #466355;\n  font-family: \"Crimson Text\";\n  font-size: 128px;\n  font-style: italic;\n  font-weight: 400;\n  line-height: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__46kyE`,
	"inner": `style_inner__nBQAS`,
	"title": `style_title__hF1gC`
};
export default ___CSS_LOADER_EXPORT___;
