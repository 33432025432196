// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_content__2mrJk {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.style_wrapper__GAczh {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  row-gap: 40px;
  padding: 0 10px;
}
.style_inner__JneNT {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 70px;
}

`, "",{"version":3,"sources":["webpack://./src/pages/CardEditPage/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,eAAe;AACjB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,mBAAmB;AACrB","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 100vh;\n}\n\n.wrapper {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  flex-direction: column;\n  row-gap: 40px;\n  padding: 0 10px;\n}\n.inner {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  margin-bottom: 70px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `style_content__2mrJk`,
	"wrapper": `style_wrapper__GAczh`,
	"inner": `style_inner__JneNT`
};
export default ___CSS_LOADER_EXPORT___;
