// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__6JHrB {
  width: 1015px;
  height: 282px;
  background: #ebe9e6;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.style_inner__3Pesk {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 25px 60px;
  justify-content: space-between;
}
.style_default_img__WzG0i {
  background-repeat: no-repeat;
  background-size: cover;
  width: 256px;
  height: 256px;
  flex-shrink: 0;
}
.style_right_block__fN\\+fQ {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.style_title__cpPyZ {
  color: #755d99;
  font-family: 'Jua';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.style_text__ru7tA {
  color: #4f4759;
  font-family: 'Jura';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 600px;
  width: 100%;
}
.style_bottom_block__rHVSn {
  border-radius: 20px;
  background: #755d99;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  max-width: 313px;
  width: 100%;
  padding: 14px 36px;
  color: #f3eaff;
  font-family: 'Jura';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
`, "",{"version":3,"sources":["webpack://./src/components/CardsComponents/PartyCards/CatCocktail/MixAndMingle/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,+CAA+C;AACjD;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,8BAA8B;AAChC;AACA;EACE,4BAA4B;EAC5B,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,cAAc;AAChB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,+CAA+C;EAC/C,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".wrapper {\n  width: 1015px;\n  height: 282px;\n  background: #ebe9e6;\n  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);\n}\n.inner {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  padding: 25px 60px;\n  justify-content: space-between;\n}\n.default_img {\n  background-repeat: no-repeat;\n  background-size: cover;\n  width: 256px;\n  height: 256px;\n  flex-shrink: 0;\n}\n.right_block {\n  display: flex;\n  flex-direction: column;\n  row-gap: 20px;\n}\n.title {\n  color: #755d99;\n  font-family: 'Jua';\n  font-size: 32px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n.text {\n  color: #4f4759;\n  font-family: 'Jura';\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  max-width: 600px;\n  width: 100%;\n}\n.bottom_block {\n  border-radius: 20px;\n  background: #755d99;\n  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);\n  max-width: 313px;\n  width: 100%;\n  padding: 14px 36px;\n  color: #f3eaff;\n  font-family: 'Jura';\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__6JHrB`,
	"inner": `style_inner__3Pesk`,
	"default_img": `style_default_img__WzG0i`,
	"right_block": `style_right_block__fN+fQ`,
	"title": `style_title__cpPyZ`,
	"text": `style_text__ru7tA`,
	"bottom_block": `style_bottom_block__rHVSn`
};
export default ___CSS_LOADER_EXPORT___;
