// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__Av8K9 {
  width: 510px;
  height: 681px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: 4px 4px 4px 0px #595959;
}
.style_inner__k23Ob {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 215px 0px 92px;
}
.style_title__E5xnE {
  color: #fff;
  font-family: 'Jomolhari';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 50px;
}
.style_title__E5xnE span:first-child {
  font-size: 27px;
}
.style_title__E5xnE span:nth-child(2) {
  font-size: 45px;
}
.style_bottom_block__J88Gd {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  margin-left: 85px;
}
.style_bottom_block__J88Gd span {
  color: #fff;
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.style_bottom_block__J88Gd span:first-child {
  letter-spacing: 2.52px;
}
.style_bottom_block__J88Gd span:nth-child(2) {
  letter-spacing: 1.2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CardsComponents/HolidayCards/Christmas/HolidayCheer/style.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,4BAA4B;EAC5B,sBAAsB;EACtB,2BAA2B;EAC3B,mCAAmC;AACrC;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,sBAAsB;EACtB,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".wrapper {\n  width: 510px;\n  height: 681px;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n  box-shadow: 4px 4px 4px 0px #595959;\n}\n.inner {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding: 215px 0px 92px;\n}\n.title {\n  color: #fff;\n  font-family: 'Jomolhari';\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  margin-right: 50px;\n}\n.title span:first-child {\n  font-size: 27px;\n}\n.title span:nth-child(2) {\n  font-size: 45px;\n}\n.bottom_block {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  align-self: flex-start;\n  margin-left: 85px;\n}\n.bottom_block span {\n  color: #fff;\n  font-family: 'Inter';\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n.bottom_block span:first-child {\n  letter-spacing: 2.52px;\n}\n.bottom_block span:nth-child(2) {\n  letter-spacing: 1.2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__Av8K9`,
	"inner": `style_inner__k23Ob`,
	"title": `style_title__E5xnE`,
	"bottom_block": `style_bottom_block__J88Gd`
};
export default ___CSS_LOADER_EXPORT___;
