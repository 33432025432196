// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__71cTV {
  background-size: cover;
  width: 510px;
  height: 665px;
  background-repeat: no-repeat;
  color: #2d1f0c;
  text-align: center;
  position: relative;
}
.style_inner__nuXI7 {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 141px 0 216px;
}
.style_title__xU\\+5u {
  margin: 55px 0 25px;
  font-size: 15px;
}
.style_top__rFOp7 {
  color: #412f16;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.style_date_time__myys6 {
  font-family: 'Forum', sans-serif;
  display: flex;
  column-gap: 5px;
}
.style_restaurant_text__y2IRq {
  font-family: 'Crimson Text', sans-serif;
}
.style_name_block__XFeln {
  color: #412f16;
  font-family: 'Fraunces', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 33px;
}
.style_bottom_text__AExvV,
.style_title__xU\\+5u {
  color: #412f16;
  text-align: center;
  font-family: 'Fraunces', sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.style_bottom_text__AExvV {
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CardsComponents/WeddingCards/CatEngagment/BloomsOfLove/style.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,4BAA4B;EAC5B,cAAc;EACd,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,gCAAgC;EAChC,aAAa;EACb,eAAe;AACjB;AACA;EACE,uCAAuC;AACzC;AACA;EACE,cAAc;EACd,mCAAmC;EACnC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;AACrB;AACA;;EAEE,cAAc;EACd,kBAAkB;EAClB,mCAAmC;EACnC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB","sourcesContent":[".wrapper {\n  background-size: cover;\n  width: 510px;\n  height: 665px;\n  background-repeat: no-repeat;\n  color: #2d1f0c;\n  text-align: center;\n  position: relative;\n}\n.inner {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 141px 0 216px;\n}\n.title {\n  margin: 55px 0 25px;\n  font-size: 15px;\n}\n.top {\n  color: #412f16;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.date_time {\n  font-family: 'Forum', sans-serif;\n  display: flex;\n  column-gap: 5px;\n}\n.restaurant_text {\n  font-family: 'Crimson Text', sans-serif;\n}\n.name_block {\n  color: #412f16;\n  font-family: 'Fraunces', sans-serif;\n  font-size: 32px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 33px;\n}\n.bottom_text,\n.title {\n  color: #412f16;\n  text-align: center;\n  font-family: 'Fraunces', sans-serif;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n}\n.bottom_text {\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__71cTV`,
	"inner": `style_inner__nuXI7`,
	"title": `style_title__xU+5u`,
	"top": `style_top__rFOp7`,
	"date_time": `style_date_time__myys6`,
	"restaurant_text": `style_restaurant_text__y2IRq`,
	"name_block": `style_name_block__XFeln`,
	"bottom_text": `style_bottom_text__AExvV`
};
export default ___CSS_LOADER_EXPORT___;
