import styles from './style.module.css';
import StartOfUsImg from '../../../../../assets/images/CardImages/CatWedding/wedding/start-of-us.png';

function Card({formData, isDateChanged}) {
  return (
    <div
      className={styles.wrapper}
      style={{backgroundImage: `url(${StartOfUsImg})`}}
    >
      <div className={styles.inner}>
        <p className={styles.date}>
          <span>{formData.dateNumber}</span>
          <span>
            {isDateChanged ? formData.dateMonthNumber : formData.dateMonth}
          </span>
          <span>
            {isDateChanged ? formData.dateYearShort : formData.dateYear}
          </span>
        </p>
        <h2 className={styles.title}>SAVE THE DATE</h2>
        <div className={styles.names}>
          {formData.brideName} <span>&</span> {formData.groomName}
        </div>
        <p className={styles.text}>
          {formData.churchName} MONASTERY | {formData.restaurantName} RESTAURANT
        </p>
      </div>
    </div>
  );
}

export default Card;
