// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_card_image_block__O3Blu {
  background-size: cover;
  width: 510px;
  height: 665px;
  background-repeat: no-repeat;
  color: #2d1f0c;
  text-align: center;
  position: relative;
  box-shadow: 10px 10px 10px 0px #9a9a9a;
}

.style_inner__QwwRN {
  position: absolute;
  bottom: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 60px;
  width: 100%;
}

.style_top__X-XLm > p {
  font-family: 'Fira Code';
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  max-width: 240px;
  width: 100%;
}

.style_mid__Kmw9g > h2 {
  font-family: 'Cormorant Infant';
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
}

.style_bottom__c\\+h1t > p {
  font-family: 'Fira Code';
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  max-width: 205px;
  width: 100%;
}
.style_church__4P9R2,
.style_restaurant__\\+M\\+MD {
  text-transform: uppercase;
}
`, "",{"version":3,"sources":["webpack://./src/components/CardsComponents/WeddingCards/CatWedding/ForeverTogether/style.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,4BAA4B;EAC5B,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,sCAAsC;AACxC;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,+BAA+B;EAC/B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;AACb;AACA;;EAEE,yBAAyB;AAC3B","sourcesContent":[".card_image_block {\n  background-size: cover;\n  width: 510px;\n  height: 665px;\n  background-repeat: no-repeat;\n  color: #2d1f0c;\n  text-align: center;\n  position: relative;\n  box-shadow: 10px 10px 10px 0px #9a9a9a;\n}\n\n.inner {\n  position: absolute;\n  bottom: 35px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  row-gap: 60px;\n  width: 100%;\n}\n\n.top > p {\n  font-family: 'Fira Code';\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 300;\n  max-width: 240px;\n  width: 100%;\n}\n\n.mid > h2 {\n  font-family: 'Cormorant Infant';\n  font-size: 40px;\n  font-style: normal;\n  font-weight: 600;\n}\n\n.bottom > p {\n  font-family: 'Fira Code';\n  font-size: 11px;\n  font-style: normal;\n  font-weight: 300;\n  max-width: 205px;\n  width: 100%;\n}\n.church,\n.restaurant {\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card_image_block": `style_card_image_block__O3Blu`,
	"inner": `style_inner__QwwRN`,
	"top": `style_top__X-XLm`,
	"mid": `style_mid__Kmw9g`,
	"bottom": `style_bottom__c+h1t`,
	"church": `style_church__4P9R2`,
	"restaurant": `style_restaurant__+M+MD`
};
export default ___CSS_LOADER_EXPORT___;
