import styles from './style.module.css';
import TimelessLoveImg from '../../../../../assets/images/CardImages/CatWedding/engagment/timeless-love.png';

function Card({formData, isDateChanged}) {
  return (
    <div
      className={styles.wrapper}
      style={{backgroundImage: `url(${TimelessLoveImg})`}}
    >
      <div className={styles.inner}>
        <div className={styles.date}>
          <span>{formData.dateNumber}</span>
          <span>
            {isDateChanged ? formData.dateMonthNumber : formData.dateMonth}
          </span>
          <span>
            {isDateChanged ? formData.dateYearShort : formData.dateYear}
          </span>
        </div>
        <h2 className={styles.title}>Wedding Anniversary of</h2>
        <div className={styles.names}>
          {formData.brideName} & {formData.groomName}
        </div>
        <div className={styles.place_time}>
          <span className={styles.time}>{formData.placeTime}</span> <span>|</span> 
          <span className={styles.time}>{formData.place} Restaurant</span>
        </div>
      </div>
    </div>
  );
}

export default Card;
