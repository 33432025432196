import styles from './style.module.css'

import Web5 from '../../assets/images/cardWebsitesImages/web-inv5.png'
import Card5 from '../../assets/images/cardWebsitesImages/card-inv5.png'

const HolidayDrop = () => {
    return (
      <div className={styles.drop_wrapper}>
        <ul className={styles.drop_list}>
          <li>
            <a href="#">Christmas</a>
          </li>
          <li>
            <a href="#">March 8</a>
          </li>
          <li>
            <a href="#">Easter</a>
          </li>
        </ul>
        <div className={styles.drop_right}>
          <div className={styles.img_web}>
            <img src={Web5} alt="holiday-website" />
            <span>Website</span>
          </div>
          <div className={styles.img_card}>
            <img src={Card5} alt="holiday-card" />
            <span>Card</span>
          </div>
        </div>
      </div>
    );
}

export default HolidayDrop
