import styles from './style.module.css';

function BreadCrambs({routes}) {
    return <div className={styles.bread_crambs}> 
        {
            routes.map((route, index) => {
                return (index !== routes.length - 1) ? route + ' > ' : route;
            })
      }
  </div>;
}
export default BreadCrambs;
