import styles from './style.module.css';
import VogueVoyage from '../../../../../assets/images/CardImages/CatParty/Opening/vogue-voyage-wrapper.png';

function Card({formData, isDateChanged}) {
  return (
    <div
      className={styles.wrapper}
      style={{backgroundImage: `url(${VogueVoyage})`}}
    >
      <div className={styles.top_text}>Grand opening</div>
      <div
        className={styles.inner}
        style={{backgroundImage: `url(${formData.defaultImg})`}}
      >
        <div className={styles.inner_top}>
          <span>{formData.title}</span>
          <span>fashion shop</span>
        </div>
        <div className={styles.inner_bottom}>
          <div className={styles.inner_bottom_text}>
            Join us for the grand opening of our shop
          </div>
          <div className={styles.date_time}>
            <span>{formData.addressTime}</span>
            <span>
              {formData.dateNumber}.
              {isDateChanged ? formData.dateMonthNumber : formData.dateMonth}.
              {isDateChanged ? formData.dateYearLong : formData.dateYear}
            </span>
          </div>
          <div className={styles.address}>{formData.address}</div>
        </div>
      </div>
      <div className={styles.bottom_text}>your style is our priority</div>
    </div>
  );
}
export default Card;
