// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_nav__KAVNM {
    width: 100%;
    display: flex;
    justify-content: center;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px #DADADA;
}

.style_list__K8rnZ {
    padding: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 86px;
    list-style: none;
    margin: unset;
}

.style_list_item__Jbpqj {
    position: relative;
}

.style_nav_link__uGZeg {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    display: inline-block;
    padding-bottom: 3px;
}

.style_nav_link__uGZeg:hover {
    font-weight: 500;
    border-bottom: 1px solid #574327;
}

.style_line__X0LgU {
    width: 100%;
    height: 1px;
    background: #574327;
    margin-top: 6px;
    /* display: none; */
}
.style_logo__2hszh {
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/style.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,mCAAmC;AACvC;;AAEA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;IACT,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,qBAAqB;IACrB,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,gCAAgC;AACpC;;AAEA;IACI,WAAW;IACX,WAAW;IACX,mBAAmB;IACnB,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,aAAa;AACjB","sourcesContent":[".nav {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    background: #FFF;\n    box-shadow: 0px 4px 4px 0px #DADADA;\n}\n\n.list {\n    padding: 28px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 86px;\n    list-style: none;\n    margin: unset;\n}\n\n.list_item {\n    position: relative;\n}\n\n.nav_link {\n    color: #000;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    text-decoration: none;\n    display: inline-block;\n    padding-bottom: 3px;\n}\n\n.nav_link:hover {\n    font-weight: 500;\n    border-bottom: 1px solid #574327;\n}\n\n.line {\n    width: 100%;\n    height: 1px;\n    background: #574327;\n    margin-top: 6px;\n    /* display: none; */\n}\n.logo {\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": `style_nav__KAVNM`,
	"list": `style_list__K8rnZ`,
	"list_item": `style_list_item__Jbpqj`,
	"nav_link": `style_nav_link__uGZeg`,
	"line": `style_line__X0LgU`,
	"logo": `style_logo__2hszh`
};
export default ___CSS_LOADER_EXPORT___;
