import styles from './style.module.css';

import OurAnniversary from '../../../../../assets/images/CardImages/CatWedding/anniversary/our-anniversary.png';

function Card({formData, isDateChanged, isTimeChanged}) {
  return (
    <div
      className={styles.wrapper}
      style={{backgroundImage: `url(${OurAnniversary})`}}
    >
      <div className={styles.inner}>
        <p className={styles.top_text}>
          We are delighted to invite you to celebrate our
        </p>
        <h3 className={styles.names}>
          {formData.husbandName} and {formData.wifeName}’s
        </h3>
        <div className={styles.mid_block}>
          <div className={styles.year}>
            <span>{formData.yearsAnniversary}</span>
            <span>th</span>
          </div>
          <span>Anniversary</span>
        </div>
        <p className={styles.text}>
          Join us as we reflect on the wonderful memories we've shared and look
          forward to many more years of love and happiness.
        </p>
        <div className={styles.bottom_block}>
          <div className={styles.bottom_left}>
            <span>{formData.place}</span>
            <span>{formData.address}</span>
          </div>
          <div className={styles.bottom_mid}>
            <span>
              {isDateChanged ? formData.dateDayLong : formData.dateDay}
            </span>
            <span>{formData.dateNumber}</span>
            <span>
              {isDateChanged ? formData.dateMonthLong : formData.dateMonth},
              {isDateChanged ? formData.dateYearLong : formData.dateYear}
            </span>
          </div>
          <div className={styles.bottom_right}>
            <span>
              Time {isTimeChanged ? formData.time24 : formData.placeTime}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Card;
