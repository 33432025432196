import styles from './style.module.css';

import TopFlower from '../../../../../assets/images/CardImages/CatBirthday/Wooman/queen-for-love-flower1.png';
import BottomFlower from '../../../../../assets/images/CardImages/CatBirthday/Wooman/queen-for-love-flower2.png';

function Card({formData, isDateChanged}) {
  return (
    <div className={styles.card_wrapper}>
      <img className={styles.wrapper_top_img} src={TopFlower} alt="" />
      <div className={styles.inner}>
        <div className={styles.top}>
          <h2 className={styles.title}>Let’s celebrate</h2>
          <p className={styles.name_text}>{formData.personName}'s</p>
          <p className={styles.text}>birthday!</p>
        </div>
        <div className={styles.bottom}>
          <div className={styles.date_text}>
            <span>
              {isDateChanged ? formData.dateDayLong : formData.dateDay},
            </span>
            <span>{formData.dateNumber}</span>
            <span>
              {isDateChanged ? formData.dateMonthLong : formData.dateMonth},
            </span>
            <span>{formData.placeTime}</span>
          </div>
          <p className={styles.bottom_text}>{formData.place}</p>
        </div>
      </div>
      <img className={styles.wrapper_bottom_img} src={BottomFlower} alt="" />
    </div>
  );
}
export default Card;
