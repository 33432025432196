// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_content__8DIHD {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}
.style_wrapper__cP1ob {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  row-gap: 40px;
}
.style_inner__W13wa {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CardPaymentPage/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,aAAa;AACjB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,aAAa;AACf;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,eAAe;AACjB","sourcesContent":[".content {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    height: 100vh;\n}\n.wrapper {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  flex-direction: column;\n  row-gap: 40px;\n}\n.inner {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `style_content__8DIHD`,
	"wrapper": `style_wrapper__cP1ob`,
	"inner": `style_inner__W13wa`
};
export default ___CSS_LOADER_EXPORT___;
