import { useParams } from 'react-router-dom';
import cards from '../../data/cards';

import Footer from '../../components/Footer/Footer';
import FormPayment from '../../components/FormPayment/FormPayment';
import Header from '../../components/Header/Header';
import Payment from '../../components/Payment/Payment';
import Search from '../../components/Search/Search';
import styles from './style.module.css';

const CardPaymentPage = () => {
  const {id} = useParams();
  const cardItem = cards.find((item) => item.id === id);

  return (
    <div className={styles.content}>
      <Header />
      <div className={styles.wrapper}>
        <Search />
        <div className="container">
          <div className={styles.inner}>
            <Payment cardItem={cardItem} />
            <FormPayment cardItem={cardItem} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CardPaymentPage;
