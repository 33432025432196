// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_bread_crambs__SbxZx {
    width: 100%;
    color: #63696C;
    margin: 41px 0 14px;
    max-width: 1440px;
}
@media screen and (max-width: 1155px) {
    .style_bread_crambs__SbxZx {
        display: flex;
        justify-content: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/BreadCrambs/style.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,cAAc;IACd,mBAAmB;IACnB,iBAAiB;AACrB;AACA;IACI;QACI,aAAa;QACb,uBAAuB;IAC3B;AACJ","sourcesContent":[".bread_crambs {\n    width: 100%;\n    color: #63696C;\n    margin: 41px 0 14px;\n    max-width: 1440px;\n}\n@media screen and (max-width: 1155px) {\n    .bread_crambs {\n        display: flex;\n        justify-content: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bread_crambs": `style_bread_crambs__SbxZx`
};
export default ___CSS_LOADER_EXPORT___;
