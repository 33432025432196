// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__gqTOZ {
  width: 510px;
  height: 665px;
  background: #fffcf7;
  box-shadow: 10px 10px 10px 0px #777;
}
.style_inner__p2yta {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 52px 0 43px;
}
.style_inner__p2yta > img {
  margin: 50px 0;
}
.style_title__L2te3,
.style_text__qWWSc,
.style_mid_left__e6MOm,
.style_address_text__uLI7h,
.style_bottom__TGACv p {
  color: #2d2d2d;
  font-family: 'Montaga', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.style_title__L2te3,
.style_text__qWWSc {
  font-size: 20px;
}
.style_title__L2te3 {
  display: flex;
  gap: 14px;
}
.style_title__L2te3 span:first-child,
.style_title__L2te3 span:nth-child(3) {
  text-transform: uppercase;
}
.style_title__L2te3 span:nth-child(2),
.style_mid_right_text__vBTGa {
  font-family: 'Miama', sans-serif;
}
.style_mid_left__e6MOm {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
}
.style_mid_left__e6MOm span:nth-child(2) {
  font-size: 36px;
}

.style_bottom__TGACv {
  font-size: 16px;
}
.style_address_text__uLI7h {
  font-size: 14px;
}
.style_mid__LRSsN {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 60px 0 68px;
  column-gap: 34px;
}
.style_line__IJnpp {
  background: #2d2d2d;
  width: 1px;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/CardsComponents/WeddingCards/CatWedding/Bliss/style.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mCAAmC;AACrC;AACA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,oBAAoB;AACtB;AACA;EACE,cAAc;AAChB;AACA;;;;;EAKE,cAAc;EACd,kCAAkC;EAClC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;;EAEE,eAAe;AACjB;AACA;EACE,aAAa;EACb,SAAS;AACX;AACA;;EAEE,yBAAyB;AAC3B;AACA;;EAEE,gCAAgC;AAClC;AACA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,UAAU;EACV,YAAY;AACd","sourcesContent":[".wrapper {\n  width: 510px;\n  height: 665px;\n  background: #fffcf7;\n  box-shadow: 10px 10px 10px 0px #777;\n}\n.inner {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 52px 0 43px;\n}\n.inner > img {\n  margin: 50px 0;\n}\n.title,\n.text,\n.mid_left,\n.address_text,\n.bottom p {\n  color: #2d2d2d;\n  font-family: 'Montaga', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n.title,\n.text {\n  font-size: 20px;\n}\n.title {\n  display: flex;\n  gap: 14px;\n}\n.title span:first-child,\n.title span:nth-child(3) {\n  text-transform: uppercase;\n}\n.title span:nth-child(2),\n.mid_right_text {\n  font-family: 'Miama', sans-serif;\n}\n.mid_left {\n  font-size: 12px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-transform: uppercase;\n}\n.mid_left span:nth-child(2) {\n  font-size: 36px;\n}\n\n.bottom {\n  font-size: 16px;\n}\n.address_text {\n  font-size: 14px;\n}\n.mid {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin: 60px 0 68px;\n  column-gap: 34px;\n}\n.line {\n  background: #2d2d2d;\n  width: 1px;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__gqTOZ`,
	"inner": `style_inner__p2yta`,
	"title": `style_title__L2te3`,
	"text": `style_text__qWWSc`,
	"mid_left": `style_mid_left__e6MOm`,
	"address_text": `style_address_text__uLI7h`,
	"bottom": `style_bottom__TGACv`,
	"mid_right_text": `style_mid_right_text__vBTGa`,
	"mid": `style_mid__LRSsN`,
	"line": `style_line__IJnpp`
};
export default ___CSS_LOADER_EXPORT___;
