// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__7Cxet {
  background-size: cover;
  width: 463px;
  height: 665px;
  background-repeat: no-repeat;
  box-shadow: 5px 5px 8px 0px #C1C1C1
}
.style_inner__GUjxf {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 210px 0 80px;
  /* margin-top: 20px; */
}
.style_title__Crgls,
.style_name__AHPGi {
  color: #043140;
  font-family: 'Tangerine';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.style_title__Crgls {
  font-size: 75px;
}
.style_name__AHPGi {
  font-size: 36px;
  margin-top: 90px;
}
.style_text__W17Zp,
.style_date__YdI4P {
  color: #a58442;
  font-family: 'Gotu';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.style_text__W17Zp {
  font-size: 24px;
   margin-top: 15px;
}
.style_date__YdI4P {
  font-size: 16px;
  margin-top: 90px;
}`, "",{"version":3,"sources":["webpack://./src/components/CardsComponents/ThankYouCards/ThankYouFirst/style.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,4BAA4B;EAC5B;AACF;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,qBAAqB;EACrB,sBAAsB;AACxB;AACA;;EAEE,cAAc;EACd,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;;EAEE,cAAc;EACd,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,eAAe;GACd,gBAAgB;AACnB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".wrapper {\n  background-size: cover;\n  width: 463px;\n  height: 665px;\n  background-repeat: no-repeat;\n  box-shadow: 5px 5px 8px 0px #C1C1C1\n}\n.inner {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 210px 0 80px;\n  /* margin-top: 20px; */\n}\n.title,\n.name {\n  color: #043140;\n  font-family: 'Tangerine';\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n.title {\n  font-size: 75px;\n}\n.name {\n  font-size: 36px;\n  margin-top: 90px;\n}\n.text,\n.date {\n  color: #a58442;\n  font-family: 'Gotu';\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n.text {\n  font-size: 24px;\n   margin-top: 15px;\n}\n.date {\n  font-size: 16px;\n  margin-top: 90px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__7Cxet`,
	"inner": `style_inner__GUjxf`,
	"title": `style_title__Crgls`,
	"name": `style_name__AHPGi`,
	"text": `style_text__W17Zp`,
	"date": `style_date__YdI4P`
};
export default ___CSS_LOADER_EXPORT___;
