
import MainBusiness from "../components/MainComponents/MainBusiness/MainBusiness"

const BusinessPage = () => {
    return (
        <>
            <MainBusiness />
        </>
    )
}

export default BusinessPage
