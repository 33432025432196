import styles from './styles.module.css'

import WeedingImg from '../../assets/images/GlobalImages/weeding.png'
import ArrowRight from '../ArrowRight/ArrowRight'
const OnlineCard = () => {
  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <h2 className={styles.title}>FREE online card</h2>
            <p className={styles.subtitle}>
              If you choose to print the cards, for orders of 40 or more, the
              online card will be free.
            </p>
            <ArrowRight arrowWeeding="true" />
          </div>
          <div className={styles.line}></div>
          <div className={styles.right}>
            <div className={styles.img}>
              <img src={WeedingImg} alt="Weeding" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OnlineCard;
