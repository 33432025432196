import styles from './style.module.css';
import HatsOffToYouimg from '../../../../../assets/images/CardImages/CatParty/Graduation/hats-off-to-you.png';
import Hat from '../../../../../assets/images/GlobalImages/graduation-hat.png';

function Card({formData, isDateChanged}) {
  return (
    <div
      className={styles.wrapper}
      style={{backgroundImage: `url(${HatsOffToYouimg})`}}
    >
      <div className={styles.inner}>
        <div className={styles.date}>
          {formData.dateNumber}/
          {isDateChanged ? formData.dateMonthNumber : formData.dateMonth}/
          {isDateChanged ? formData.dateYearLong : formData.dateYear}
        </div>
        <img src={Hat} alt="hat" />
        <div className={styles.name}>
          <span>{formData.personName}'s</span>
          <span>Graduation</span>
        </div>

        <div className={styles.text}>
          You’re invited to celebrate {formData.personName}’s graduation from
          {formData.school} on {formData.dateNumber}/
          {isDateChanged ? formData.dateMonthNumber : formData.dateMonth}/
          {isDateChanged ? formData.dateYearLong : formData.dateYear} at
          <span style={{marginLeft: '5px'}}>{formData.placeTime}</span>. The
          ceremony will be at {formData.place}. We look forward to sharing this
          special moment with you.
        </div>
      </div>
    </div>
  );
}
export default Card;
