import {  useState } from 'react';
import styles from './style.module.css';
import { NavLink } from 'react-router-dom';
import Wrapper from '../NavbarComponents/Wrapper';

// import InWorx from '../../assets/icons/InWorx.svg'

const Navbar = () => {

    const [activeTab, setActiveTab] = useState('')

    return (
        <>
            <nav className={styles.nav} onMouseOver={() => setActiveTab('')}>
                {/* <div className={styles.logo}>
                    <img src={InWorx} alt="InWorx" />
                </div> */}
                <ul className={styles.list} onMouseOver={(e) => e.stopPropagation()}>
                    <li className={styles.list_item} onMouseOver={(e) => setActiveTab(e.target.innerText)}>
                        <NavLink className={styles.nav_link} id='navList' style={({ isActive }) => {
                            return isActive ? { borderBottom: "1px solid #574327", pointerEvents: 'none' } : {};
                        }} to="/wedding">Wedding</NavLink>
                        {/* <div className={styles.line}></div> */}
                    </li>
                    <li className={styles.list_item} onMouseOver={(e) => setActiveTab(e.target.innerText)}>
                        <NavLink className={styles.nav_link} style={({ isActive }) => {
                            return isActive ? { borderBottom: "1px solid #574327", pointerEvents: 'none' } : {};
                        }} to="/birthday">Birthday</NavLink>
                        {/* <div className={styles.line}></div> */}
                    </li>
                    <li className={styles.list_item} onMouseOver={(e) => setActiveTab(e.target.innerText)}>
                        <NavLink className={styles.nav_link} style={({ isActive }) => {
                            return isActive ? { borderBottom: "1px solid #574327", pointerEvents: 'none' } : {};
                        }} to="/party">Party</NavLink>
                        {/* <div className={styles.line}></div> */}
                    </li>
                    <li className={styles.list_item} onMouseOver={(e) => setActiveTab(e.target.innerText)}>
                        <NavLink className={styles.nav_link} style={({ isActive }) => {
                            return isActive ? { borderBottom: "1px solid #574327", pointerEvents: 'none' } : {};
                        }} to="/business">Business</NavLink>
                        {/* <div className={styles.line}></div> */}
                    </li>
                    <li className={styles.list_item} onMouseOver={(e) => setActiveTab(e.target.innerText)}>
                        <NavLink className={styles.nav_link} style={({ isActive }) => {
                            return isActive ? { borderBottom: "1px solid #574327", pointerEvents: 'none' } : {};
                        }} to="/holiday">Holiday</NavLink>
                        {/* <div className={styles.line}></div> */}
                    </li>
                </ul>
            </nav>
            {activeTab && <Wrapper activeTab={activeTab} setActiveTab={setActiveTab} />}
        </>

    )
}

export default Navbar
