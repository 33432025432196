// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_picker_block__TawTb {
  width: 30%;
  position: relative;
}
.style_input_wrapper__x-E5p {
  width: 100%;
  height: 48px;
  border-radius: 10px;
  border: 1px solid #b7b7b7;
  background: #fff;
  padding: 13px;
  display: flex;
  justify-content: space-between;
}
.style_input_wrapper__x-E5p img {
  cursor: pointer;
}

.style_input__eZf4D {
  width: 50%;
  color: #000;
  border: none;
  outline: none;
  font-size: 15px;
}
.style_input__eZf4D::placeholder {
  color: #b7b7b7;
  font-size: 15px;
}
.style_picker_inner__6tY4H {
  border-radius: 10px;
  border: 1px solid #b7b7b7;
  background: #fff;
  width: 125%;
  height: 80px;
  margin-top: 5px;
  position: absolute;
  padding: 0;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  z-index: 10;
}
.style_time_first_block__0-B\\+f,
.style_time_second_block__V0v1k {
  width: 40px;
  height: 33px;
  border: 1px solid #b7b7b7;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  border-radius: 10px;
}
.style_left__sB9qq,
.style_right__OuzW8 {
  text-align: center;
}
.style_bottom_arrow__RT8BN img,
.style_top_arrow__ScBSR img {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Form/TimePickerComponent/style.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;EACb,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,YAAY;EACZ,aAAa;EACb,eAAe;AACjB;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;AACb;AACA;;EAEE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,mBAAmB;AACrB;AACA;;EAEE,kBAAkB;AACpB;AACA;;EAEE,eAAe;AACjB","sourcesContent":[".picker_block {\n  width: 30%;\n  position: relative;\n}\n.input_wrapper {\n  width: 100%;\n  height: 48px;\n  border-radius: 10px;\n  border: 1px solid #b7b7b7;\n  background: #fff;\n  padding: 13px;\n  display: flex;\n  justify-content: space-between;\n}\n.input_wrapper img {\n  cursor: pointer;\n}\n\n.input {\n  width: 50%;\n  color: #000;\n  border: none;\n  outline: none;\n  font-size: 15px;\n}\n.input::placeholder {\n  color: #b7b7b7;\n  font-size: 15px;\n}\n.picker_inner {\n  border-radius: 10px;\n  border: 1px solid #b7b7b7;\n  background: #fff;\n  width: 125%;\n  height: 80px;\n  margin-top: 5px;\n  position: absolute;\n  padding: 0;\n  left: 0px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  column-gap: 10px;\n  z-index: 10;\n}\n.time_first_block,\n.time_second_block {\n  width: 40px;\n  height: 33px;\n  border: 1px solid #b7b7b7;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 15px;\n  border-radius: 10px;\n}\n.left,\n.right {\n  text-align: center;\n}\n.bottom_arrow img,\n.top_arrow img {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"picker_block": `style_picker_block__TawTb`,
	"input_wrapper": `style_input_wrapper__x-E5p`,
	"input": `style_input__eZf4D`,
	"picker_inner": `style_picker_inner__6tY4H`,
	"time_first_block": `style_time_first_block__0-B+f`,
	"time_second_block": `style_time_second_block__V0v1k`,
	"left": `style_left__sB9qq`,
	"right": `style_right__OuzW8`,
	"bottom_arrow": `style_bottom_arrow__RT8BN`,
	"top_arrow": `style_top_arrow__ScBSR`
};
export default ___CSS_LOADER_EXPORT___;
