import styles from './style.module.css';
import ForeverCardImg from '../../../../../assets/images/CardImages/CatWedding/wedding/foreverTogether.png'

const Card = ({ formData, cardDetails, isDateChanged }) => {
  return (
    <div
      className={styles.card_image_block}
      style={{backgroundImage: `url(${ForeverCardImg})`}}
    >
      <div className={styles.inner}>
        <div className={styles.top}>
          <p className={styles.top_text}>
            YOU ARE INVITED TO CELEBRATE THE WEDDING OF
          </p>
        </div>
        <div className={styles.mid}>
          <h2 className={styles.title}>
            {isDateChanged || cardDetails
              ? formData.groomName
              : formData.groomName}
            & {formData.brideName}
          </h2>
        </div>
        <div className={styles.bottom}>
          <p>
            {isDateChanged || cardDetails
              ? formData.dateDayLong
              : formData.dateDay}
            ,THE
            <span style={{marginLeft: '5px'}}>{formData.dateNumber}TH OF</span>
            <span style={{marginLeft: '5px'}}>
              {isDateChanged || cardDetails
                ? formData.dateMonthLong
                : formData.dateMonth}
            </span>
          </p>
          <p className={styles.church}>
            {isDateChanged || cardDetails
              ? formData.churchTime
              : formData.churchTime}
            AT {formData.churchName} MONASTERY
          </p>
          <p className={styles.restaurant}>
            {isDateChanged || cardDetails
              ? formData.restaurantTime
              : formData.restaurantTime}
            AT {formData.restaurantName} RESTAURANT
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card;
