import ForeverTogetherItem from '../assets/CardItems/forever-together.png';
import ForeverTogetherBg from '../assets/images/CardImages/CatWedding/wedding/foreverTogether-bg.png';
import BlissItem from '../assets/CardItems/bliss.png';
import BlissBg from '../assets/images/CardImages/CatWedding/wedding/bliss-bg.png';
import RoyalItem from '../assets/CardItems/royal-item.png';
import RoyalBg from '../assets/images/CardImages/CatWedding/wedding/royal-bg.png';
import BloomsOfloveItem from '../assets/CardItems/blooms-love.png';
import BloomsOfloveBg from '../assets/images/CardImages/CatWedding/engagment/blooms-of-love-bg.png';
import QueenForDayItem from '../assets/CardItems/queen-for-day_item.png';
import QueenForDayBg from '../assets/images/CardImages/CatBirthday/Wooman/queen-for-day-bg.png';
import WoomanDayItem from '../assets/CardItems/wooman-day-item.png';
import WoomanDayBg from '../assets/images/CardImages/CatHolidays/March8/wooman-day-bg.png';
import WeWelcomeYouItem from '../assets/CardItems/we-welcome-you-item.png';
import WeWelcomeYouDefault from '../assets/images/CardImages/CatParty/Gender/we-welcome-you.png';
import WeWelcomeYouBg from '../assets/images/CardImages/CatParty/Gender/we-welcome-you-bg.png';
import StartOfUsItem from '../assets/CardItems/start-of-us-item.png';
import StartOfUsBg from '../assets/images/CardImages/CatWedding/wedding/start-of-us-bg.png';
import LoveUnitesItem from '../assets/CardItems/love-unites-item.png';
import LoveUnitesBg from '../assets/images/CardImages/CatWedding/wedding/love-unites-bg.png';
import NewChapterItem from '../assets/CardItems/new-chapter-item.png';
import NewChapterBg from '../assets/images/CardImages/CatWedding/engagment/new-chapter-bg.png';
import ThankYouFirstItem from '../assets/CardItems/thankYou-1-item.png';
import ThankYouFirstBg from '../assets/images/CardImages/CatThankYou/thankYou-1-bg.png';
import ThankYouSecondItem from '../assets/CardItems/thankyou-2-item.png';
import ThankYouSecondBg from '../assets/images/CardImages/CatThankYou/thankyou-2-bg.png';
import ThankYouThirdItem from '../assets/CardItems/thankyou-3-item.png';
import ThankYouThirdBg from '../assets/images/CardImages/CatThankYou/thankYou-3-bg.png';
import OurAnniversaryItem from '../assets/CardItems/our-anniversary-item.png';
import OurAnniversaryBg from '../assets/images/CardImages/CatWedding/anniversary/our-anniversary-bg.png';
import ConferenceCallItem from '../assets/CardItems/conference-call-item.png';
import ConferenceCallBg from '../assets/images/CardImages/CatBusiness/Conference/conference-call-bg.png';
import GirlyGetAwayItem from '../assets/CardItems/girly-getaway-item.png';
import GirlyGetAwayBg from '../assets/images/CardImages/CatParty/Girls/girly-getaway-bg.png';
import GranddadBirthdayItem from '../assets/CardItems/granddad-birthday-item.png';
import GranddadBirthdayBg from '../assets/images/CardImages/CatBirthday/Man/granddad-birthday-bg.png';
import HolidayCheerItem from '../assets/CardItems/holiday-cheer-item.png';
import HolidayCheerBg from '../assets/images/CardImages/CatHolidays/Christmas/holiday-cheer-bg.png';
import ChristmasPartyItem from '../assets/CardItems/christmas-party-item.png';
import ChristmasPartyBg from '../assets/images/CardImages/CatHolidays/Christmas/christmas-party-bg.png';
import JingleMingleItem from '../assets/CardItems/jingle-mingle-item.png';
import JingleMingleBg from '../assets/images/CardImages/CatHolidays/Christmas/jingle-mingle-bg.png';
import AdventurePartyItem from '../assets/CardItems/adventure-party-item.png';
import AdventurePartyBg from '../assets/images/CardImages/CatBirthday/Kids/adventure-party-bg.png';
import BroBashItem from '../assets/CardItems/bro-bash-item.png';
import BroBashBg from '../assets/images/CardImages/CatParty/Guy/bro-bash-bg.png';
import OpeningGalaItem from '../assets/CardItems/opening-gala-item.png';
import OpeningGalaBg from '../assets/images/CardImages/CatParty/Opening/opening-gala-bg.png';
import TimelessLoveItem from '../assets/CardItems/timeless-love-item.png';
import TimelessLoveBg from '../assets/images/CardImages/CatWedding/engagment/timeless-love-bg.png';
import DivineBlessingItem from '../assets/CardItems/divine-blessing-item.png';
import DivineBlessingBg from '../assets/images/CardImages/CatParty/Christenity/divine-blessing-bg.png';
import VogueVoyageItem from '../assets/CardItems/vogue-voyage-item.png';
import VogueVoyageBg from '../assets/images/CardImages/CatParty/Opening/vogue-voyage-bg.png';
import VogueVoyageDefault from '../assets/images/CardImages/CatParty/Opening/vogue-voyage.png';
import HatsOffToYouItem from '../assets/CardItems/hats-off-to-you-item.png';
import HatsOffToYouBg from '../assets/images/CardImages/CatParty/Graduation/hats-off-to-you-bg.png';
import MixAndMingleItem from '../assets/CardItems/mix-mingle-item.png';
import MixAndMingleBg from '../assets/images/CardImages/CatParty/Cocktail/mix-mingle-bg.png';
import MixAndMingleDefault from '../assets/images/CardImages/CatParty/Cocktail/mix-mingle-default.png';

const cards = [
  {
    id: 1,
    category: 'Wedding',
    subCategory: 'Wedding',
    img: ForeverTogetherItem,
    bg: ForeverTogetherBg,
    defaultImg: '',
    name: 'Forever Together',
    placeholder: '',
    placePlaceholder: '',
    age: '',
    price: 1000,
    title: '',
    phone: '',
    email: '',
    groomName: 'Rena',
    groomSurname: '',
    brideName: 'Patrick',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: 'Sunday',
    dateNumber: '15',
    dateMonth: 'SEPTEMBER',
    dateYear: '',
    yearsAnniversary: '',
    churchName: 'SAGHMOSAVANQ',
    churchTime: '13:00',
    restaurantName: 'MORENA',
    restaurantTime: '18:00',
    time: '',
    place: '',
    placeTime: '',
    address: '',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: true,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: true,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: false,
    placeTimeAddress_yorn: false,

    groomName_yorn: true,
    groomSurname_yorn: false,
    brideName_yorn: true,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: true,
    churchName_yorn: true,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: false,
    address_yorn: false,
    withTime_yorn: true,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 2,
    category: 'Wedding',
    subCategory: 'Wedding',
    img: BlissItem,
    bg: BlissBg,
    defaultImg: '',
    name: 'Bliss',
    placeholder: '',
    placePlaceholder: '',
    age: '',
    price: 2000,
    title: '',
    phone: '',
    email: '',
    groomName: 'LEONA',
    groomSurname: '',
    brideName: 'MICHAEL',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: 'SATURDAY',
    dateNumber: '25',
    dateMonth: 'NOVEMBER',
    dateYear: '',
    yearsAnniversary: '',
    churchName: '',
    churchTime: '',
    restaurantName: 'Serenade',
    restaurantAddress: '17 Kasyan str.',
    restaurantTime: '6',
    time: '',
    place: '',
    placeTime: '',
    address: '',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: 'Gyumri',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: true,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: false,
    placeTimeAddress_yorn: false,

    groomName_yorn: true,
    groomSurname_yorn: false,
    brideName_yorn: true,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: true,
    churchName_yorn: false,
    restaurant_address_yorn: true,
    church_address_yorn: false,
    place_yorn: false,
    address_yorn: false,
    withTime_yorn: true,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 3,
    category: 'Wedding',
    subCategory: 'Wedding',
    img: RoyalItem,
    bg: RoyalBg,
    defaultImg: '',
    name: 'Royal',
    placeholder: '',
    placePlaceholder: '',
    age: '',
    price: 2500,
    title: '',
    phone: '',
    email: '',
    groomName: 'Venus',
    groomSurname: '',
    brideName: 'Emmanuel',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: 'SUNDAY',
    dateNumber: '25',
    dateMonth: 'MARCH',
    dateYear: '2025',
    yearsAnniversary: '',
    churchName: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '7',
    place: '',
    placeTime: '',
    address: '',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: true,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: false,
    placeTimeAddress_yorn: false,

    groomName_yorn: true,
    groomSurname_yorn: false,
    brideName_yorn: true,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: false,
    address_yorn: false,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 4,
    category: 'Wedding',
    subCategory: 'Engagment',
    img: BloomsOfloveItem,
    bg: BloomsOfloveBg,
    defaultImg: '',
    name: 'Blooms of Love',
    placeholder: '',
    placePlaceholder: '',
    age: '',
    price: 3000,
    title: '',
    phone: '',
    email: '',
    groomName: 'Megan',
    groomSurname: '',
    brideName: 'David',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: '',
    dateNumber: '3',
    dateMonth: 'June',
    dateYear: '',
    yearsAnniversary: '',
    churchName: '',
    churchTime: '',
    restaurantName: 'Serenade',
    restaurantAddress: '',
    restaurantTime: '18:00',
    time: '',
    place: '',
    placeTime: '',
    address: '',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: true,
    churchTime_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: false,
    schollTime_yorn: false,
    groomName_yorn: true,
    groomSurname_yorn: false,
    brideName_yorn: true,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: true,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: false,
    address_yorn: false,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 5,
    category: 'Birthday',
    subCategory: 'Wooman',
    img: QueenForDayItem,
    bg: QueenForDayBg,
    defaultImg: '',
    name: 'Queen for a Day',
    placeholder: '',
    placePlaceholder: 'Place',
    age: '',
    price: 4000,
    title: '',
    phone: '',
    email: '',
    groomName: '',
    groomSurname: '',
    brideName: '',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: 'Anna',
    dateDay: 'Sunday',
    dateNumber: '20',
    dateMonth: 'JUNE',
    yearsAnniversary: '',
    dateYear: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: 'Serenade',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: 'Our Home',
    placeTime: '18:00',
    address: '',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: true,
    placeTimeAddress_yorn: false,

    groomName_yorn: false,
    groomSurname_yorn: false,
    brideName_yorn: false,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: true,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: true,
    address_yorn: false,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 6,
    category: 'Holiday',
    subCategory: 'March 8',
    img: WoomanDayItem,
    bg: WoomanDayBg,
    defaultImg: '',
    name: 'Women Day',
    placeholder: '',
    placePlaceholder: 'Place',
    age: '',
    price: 4500,
    title: '',
    phone: '',
    email: '',
    groomName: '',
    groomSurname: '',
    brideName: '',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: '',
    dateNumber: '5',
    dateMonth: 'JUNE',
    dateYear: '',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: 'Serenade',
    placeTime: '19:00',
    address: '',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: true,
    placeTimeAddress_yorn: false,

    groomName_yorn: false,
    groomSurname_yorn: false,
    brideName_yorn: false,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: true,
    address_yorn: false,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 7,
    category: 'Party',
    subCategory: 'Gender Party',
    img: WeWelcomeYouItem,
    bg: WeWelcomeYouBg,
    defaultImg: WeWelcomeYouDefault,
    name: 'We Welcome You',
    placeholder: '',
    placePlaceholder: 'Place',
    age: '',
    price: 5000,
    title: '',
    phone: '',
    email: '',
    groomName: '',
    groomSurname: '',
    brideName: '',
    brideSurname: '',
    fatherName: 'Ben',
    motherName: 'Helen',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: 'monday',
    dateNumber: '01',
    dateMonth: '12',
    dateYear: '2024',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: 'Our Home',
    placeTime: '16:00',
    address: '',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: true,
    placeTimeAddress_yorn: false,

    groomName_yorn: false,
    groomSurname_yorn: false,
    brideName_yorn: false,
    brideSurname_yorn: false,
    fatherName_yorn: true,
    motherName_yorn: true,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: true,
    address_yorn: false,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: true,
    uploadImg_yorn: true,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 8,
    category: 'Wedding',
    subCategory: 'Wedding',
    img: StartOfUsItem,
    bg: StartOfUsBg,
    defaultImg: '',
    name: 'Start of us',
    placeholder: '',
    placePlaceholder: '',
    age: '',
    price: 4500,
    title: '',
    phone: '',
    email: '',
    groomName: 'Liana',
    groomSurname: '',
    brideName: 'David',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: '',
    dateNumber: '11',
    dateMonth: '11',
    dateYear: '24',
    yearsAnniversary: '',
    churchName: 'SAGHMOSAVANQ',
    churchAddress: '',
    churchTime: '',
    restaurantName: 'MORENA',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: '',
    placeTime: '',
    address: '',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: false,
    placeTimeAddress_yorn: false,

    groomName_yorn: true,
    groomSurname_yorn: false,
    brideName_yorn: true,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: true,
    churchName_yorn: true,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: false,
    address_yorn: false,
    withTime_yorn: true,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    wideWidth_yorn: true,
    noEdit_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 9,
    category: 'Wedding',
    subCategory: 'Wedding',
    img: LoveUnitesItem,
    bg: LoveUnitesBg,
    defaultImg: '',
    name: 'Love unites',
    placePlaceholder: '',
    placeholder: '',
    age: '',
    price: 4500,
    title: '',
    phone: '',
    email: '',
    groomName: 'Lilit',
    groomSurname: '',
    brideName: 'David',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: '',
    dateNumber: '05',
    dateMonth: '06',
    dateYear: '2024',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: '',
    placeTime: '',
    address: '',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: false,
    placeTimeAddress_yorn: false,

    groomName_yorn: true,
    groomSurname_yorn: false,
    brideName_yorn: true,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: false,
    address_yorn: false,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 10,
    category: 'Wedding',
    subCategory: 'Engagment',
    img: NewChapterItem,
    bg: NewChapterBg,
    defaultImg: '',
    name: 'A New Chapter',
    placeholder: '',
    placePlaceholder: 'Place',
    age: '',
    price: 4000,
    title: '',
    phone: '',
    email: '',
    groomName: 'Mariam',
    groomSurname: 'Abelyan',
    brideName: 'David',
    brideSurname: 'Grigoryan',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: 'Saturday',
    dateNumber: '05',
    dateMonth: 'June',
    dateYear: '2024',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: 'Serenade',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: '',
    placeTime: '16:00',
    address: 'Gyumri, Kasyan 16',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: true,
    placeTimeAddress_yorn: false,

    groomName_yorn: true,
    groomSurname_yorn: true,
    brideName_yorn: true,
    brideSurname_yorn: true,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: true,
    address_yorn: true,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 11,
    category: 'Wedding',
    subCategory: 'Thankyou',
    img: ThankYouFirstItem,
    bg: ThankYouFirstBg,
    defaultImg: '',
    name: 'Thank You 1',
    placeholder: '',
    placePlaceholder: '',
    age: '',
    price: 1000,
    title: '',
    phone: '',
    email: '',
    groomName: 'Julianna',
    groomSurname: '',
    brideName: 'Alfredo',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: '',
    dateNumber: '22',
    dateMonth: '05',
    dateYear: '2024',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: '',
    placeTime: '',
    address: '',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    addressTime_yorn: false,
    churchTimeAddress_yorn: false,
    placeTime_yorn: false,
    placeTimeAddress_yorn: false,

    groomName_yorn: true,
    groomSurname_yorn: false,
    brideName_yorn: true,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: false,
    address_yorn: false,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 12,
    category: 'Wedding',
    subCategory: 'Anniversary',
    img: OurAnniversaryItem,
    bg: OurAnniversaryBg,
    defaultImg: '',
    name: 'Our Anniversary',
    placeholder: '',
    placePlaceholder: 'Place',
    age: '',
    price: 6000,
    title: '',
    phone: '',
    email: '',
    groomName: '',
    groomSurname: '',
    brideName: '',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: 'Paul',
    wifeName: 'Emma',
    personName: '',
    dateDay: 'THURSDAY',
    dateNumber: '29',
    dateMonth: 'February',
    dateYear: '2024',
    yearsAnniversary: '10',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: 'Our Home',
    placeTime: '18:00',
    address: 'Kasyan 23/5',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: true,
    placeTimeAddress_yorn: false,

    groomName_yorn: false,
    groomSurname_yorn: false,
    brideName_yorn: false,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: true,
    husbandName_yorn: true,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: true,
    address_yorn: true,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: true,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    wideWidth_yorn: false,
    noEdit_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 13,
    category: 'Wedding',
    subCategory: 'Thankyou',
    img: ThankYouSecondItem,
    bg: ThankYouSecondBg,
    defaultImg: '',
    name: 'Thank You 2',
    placeholder: '',
    placePlaceholder: '',
    age: '',
    price: 1000,
    title: '',
    phone: '',
    email: '',
    groomName: '',
    groomSurname: '',
    brideName: '',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: '',
    dateNumber: '',
    dateMonth: '',
    dateYear: '',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: '',
    placeTime: '',
    address: '',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: false,
    placeTimeAddress_yorn: false,

    groomName_yorn: false,
    groomSurname_yorn: false,
    brideName_yorn: false,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: false,
    address_yorn: false,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    wideWidth_yorn: true,
    noEdit_yorn: true,
    longWide_yorn: false,
  },
  {
    id: 14,
    category: 'Wedding',
    subCategory: 'Thankyou',
    img: ThankYouThirdItem,
    bg: ThankYouThirdBg,
    defaultImg: '',
    name: 'Thank You 3',
    placeholder: '',
    placePlaceholder: '',
    age: '',
    price: 1000,
    title: '',
    phone: '',
    email: '',
    groomName: '',
    groomSurname: '',
    brideName: '',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: '',
    dateNumber: '',
    dateMonth: '',
    dateYear: '',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: '',
    placeTime: '',
    address: '',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: false,
    placeTimeAddress_yorn: false,

    groomName_yorn: false,
    groomSurname_yorn: false,
    brideName_yorn: false,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: false,
    address_yorn: false,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    wideWidth_yorn: true,
    noEdit_yorn: true,
    longWide_yorn: false,
  },
  {
    id: 15,
    category: 'Business',
    subCategory: 'Conference',
    img: ConferenceCallItem,
    bg: ConferenceCallBg,
    placeholder: 'Conference title',
    placePlaceholder: 'Place',
    defaultImg: '',
    name: 'Conference Call',
    age: '',
    price: 7000,
    title: 'Business',
    phone: '+374 41 95 59 05',
    email: 'info@worx.am',
    groomName: '',
    groomSurname: '',
    brideName: '',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: 'Sunday',
    dateNumber: '5',
    dateMonth: 'NOV',
    dateYear: '2023',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: 'Gustav Conference Room',
    placeTime: '16:00',
    address: '12 Kasyan Str.',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: 'Gyumri',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: true,
    placeTimeAddress_yorn: false,

    groomName_yorn: false,
    groomSurname_yorn: false,
    brideName_yorn: false,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: true,
    address_yorn: true,
    withTime_yorn: false,
    email_yorn: true,
    phone_yorn: true,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: true,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 16,
    category: 'Party',
    subCategory: 'GirlsParty',
    img: GirlyGetAwayItem,
    bg: GirlyGetAwayBg,
    defaultImg: '',
    name: 'Girly getaway',
    placeholder: '',
    placePlaceholder: 'Place',
    age: '',
    price: 5000,
    title: '',
    phone: '',
    email: '',
    groomName: '',
    groomSurname: '',
    brideName: '',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: '',
    dateNumber: '22',
    dateMonth: 'June',
    dateYear: '2024',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: 'Central park',
    placeTime: '18:00',
    address: 'Kasyan 22',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: true,
    placeTimeAddress_yorn: false,

    groomName_yorn: false,
    groomSurname_yorn: false,
    brideName_yorn: false,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: true,
    address_yorn: true,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: true,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 17,
    category: 'Birthday',
    subCategory: 'Man',
    img: GranddadBirthdayItem,
    bg: GranddadBirthdayBg,
    defaultImg: '',
    name: 'Granddad Birthday',
    placeholder: '',
    placePlaceholder: 'Place',
    age: '',
    price: 5000,
    title: '',
    phone: '',
    email: '',
    groomName: '',
    groomSurname: '',
    brideName: '',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: 'Mike',
    dateDay: 'THURSDAY',
    dateNumber: '29',
    dateMonth: 'February',
    dateYear: '2024',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: 'Our Home',
    placeTime: '18:00',
    address: 'Kasyan 23/5',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: true,
    placeTimeAddress_yorn: false,

    groomName_yorn: false,
    groomSurname_yorn: false,
    brideName_yorn: false,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: true,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: true,
    address_yorn: true,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 18,
    category: 'Holiday',
    subCategory: 'Christmas',
    img: HolidayCheerItem,
    bg: HolidayCheerBg,
    defaultImg: '',
    name: 'Holiday Cheer',
    placeholder: '',
    placePlaceholder: 'Place',
    age: '',
    price: 5500,
    title: '',
    phone: '',
    email: '',
    groomName: '',
    groomSurname: '',
    brideName: '',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: '',
    dateNumber: '31',
    dateMonth: '12',
    dateYear: '2024',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: 'Serenade Restaurant',
    placeTime: '20:00',
    address: '',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: true,
    placeTimeAddress_yorn: false,

    groomName_yorn: false,
    groomSurname_yorn: false,
    brideName_yorn: false,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: true,
    address_yorn: false,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 19,
    category: 'Holiday',
    subCategory: 'Christmas',
    img: ChristmasPartyItem,
    bg: ChristmasPartyBg,
    defaultImg: '',
    name: 'Christmas party',
    placeholder: '',
    placePlaceholder: 'Place',
    age: '',
    price: 5500,
    title: '',
    phone: '',
    email: '',
    groomName: '',
    groomSurname: '',
    brideName: '',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: 'Name',
    dateDay: '',
    dateNumber: '21',
    dateMonth: 'DEC',
    dateYear: '',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: 'LOVELY WAY ST.',
    placeTime: '6',
    address: 'FHIS CHTY, ST 12345',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: true,
    placeTimeAddress_yorn: false,

    groomName_yorn: false,
    groomSurname_yorn: false,
    brideName_yorn: false,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: true,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: true,
    address_yorn: true,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 20,
    category: 'Holiday',
    subCategory: 'Christmas',
    img: JingleMingleItem,
    bg: JingleMingleBg,
    defaultImg: '',
    name: 'Jingle Mingle',
    placeholder: '',
    placePlaceholder: 'Place',
    age: '',
    price: 5500,
    title: '',
    phone: '',
    email: '',
    groomName: '',
    groomSurname: '',
    brideName: '',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: '',
    dateNumber: '31',
    dateMonth: '12',
    dateYear: '2024',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: 'Serenade Restaurant',
    placeTime: '23:00',
    address: '',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: true,
    placeTimeAddress_yorn: false,

    groomName_yorn: false,
    groomSurname_yorn: false,
    brideName_yorn: false,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: true,
    address_yorn: false,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 21,
    category: 'Birthday',
    subCategory: 'Kids',
    img: AdventurePartyItem,
    bg: AdventurePartyBg,
    defaultImg: '',
    name: 'Adventure Party',
    placeholder: '',
    placePlaceholder: 'Place',
    age: '2',
    price: 5500,
    title: '',
    phone: '',
    email: '',
    groomName: '',
    groomSurname: '',
    brideName: '',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: 'MIKE',
    dateDay: '',
    dateNumber: '25',
    dateMonth: '02',
    dateYear: '23',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: 'Party House',
    placeTime: '17:00',
    address: 'Street of the party venue, 1234.',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: true,
    placeTimeAddress_yorn: false,

    groomName_yorn: false,
    groomSurname_yorn: false,
    brideName_yorn: false,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: true,
    age_yorn: true,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: true,
    address_yorn: true,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 22,
    category: 'Party',
    subCategory: 'Guy',
    img: BroBashItem,
    bg: BroBashBg,
    defaultImg: '',
    name: 'Bro Bash',
    placeholder: '',
    placePlaceholder: 'Place',
    age: '',
    price: 5500,
    title: '',
    phone: '',
    email: '',
    groomName: '',
    groomSurname: '',
    brideName: '',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: '',
    dateNumber: '22',
    dateMonth: 'June',
    dateYear: '24',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: 'Central Park',
    placeTime: '18:00',
    address: 'Kasyan 22',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: true,
    placeTimeAddress_yorn: false,

    groomName_yorn: false,
    groomSurname_yorn: false,
    brideName_yorn: false,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: true,
    address_yorn: true,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: true,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 23,
    category: 'Party',
    subCategory: 'Opening',
    img: OpeningGalaItem,
    bg: OpeningGalaBg,
    defaultImg: '',
    name: 'Opening Gala',
    placeholder: 'Opening Ceremony name',
    placePlaceholder: 'Place',
    age: '',
    price: 5500,
    title: 'Opening Ceromony',
    phone: '041 95 59 05',
    email: '',
    groomName: '',
    groomSurname: '',
    brideName: '',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: '',
    dateNumber: '13',
    dateMonth: '04',
    dateYear: '2024',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: 'Fashion Shop',
    placeTime: '15:00',
    address: 'Kasyan 25/5',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: true,
    placeTimeAddress_yorn: false,

    groomName_yorn: false,
    groomSurname_yorn: false,
    brideName_yorn: false,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: true,
    address_yorn: true,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: true,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: true,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 24,
    category: 'Wedding',
    subCategory: 'Engagment',
    img: TimelessLoveItem,
    bg: TimelessLoveBg,
    defaultImg: '',
    name: 'Timeless Love',
    placeholder: '',
    placePlaceholder: 'Place',
    age: '',
    price: 5500,
    title: '',
    phone: '',
    email: '',
    groomName: 'Liana',
    groomSurname: '',
    brideName: 'Davit',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: '',
    dateNumber: '11',
    dateMonth: '11',
    dateYear: '24',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: 'Morena',
    placeTime: '17:00',
    address: '',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: true,
    placeTimeAddress_yorn: false,

    groomName_yorn: true,
    groomSurname_yorn: false,
    brideName_yorn: true,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: true,
    address_yorn: false,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: true,
    longWide_yorn: false,
  },
  {
    id: 25,
    category: 'Party',
    subCategory: 'Christenity',
    img: DivineBlessingItem,
    bg: DivineBlessingBg,
    defaultImg: '',
    name: 'Divine Blessing',
    placeholder: '',
    placePlaceholder: '',
    age: '',
    price: 5500,
    title: '',
    phone: '',
    email: '',
    groomName: '',
    groomSurname: '',
    brideName: '',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: 'Robert',
    dateDay: '',
    dateNumber: '14',
    dateMonth: 'June',
    dateYear: '',
    yearsAnniversary: '',
    churchName: 'Saghmosavanq',
    churchAddress: '',
    churchTime: '13:00',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: 'Morena',
    placeTime: '17:00',
    address: '',
    addressTime: '',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: true,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: false,
    placeTimeAddress_yorn: false,

    groomName_yorn: false,
    groomSurname_yorn: false,
    brideName_yorn: false,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: true,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: true,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: false,
    address_yorn: false,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: true,
    longWide_yorn: false,
  },
  {
    id: 26,
    category: 'Party',
    subCategory: 'Opening',
    img: VogueVoyageItem,
    bg: VogueVoyageBg,
    defaultImg: VogueVoyageDefault,
    name: 'Vogue Voyage',
    placeholder: 'Shop name',
    placePlaceholder: 'Place',
    age: '',
    price: 5500,
    title: 'Elegant',
    phone: '',
    email: '',
    groomName: '',
    groomSurname: '',
    brideName: '',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: '',
    dateDay: '',
    dateNumber: '25',
    dateMonth: '06',
    dateYear: '2025',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: '',
    placeTime: '',
    address: 'Kasyan 25',
    addressTime: '15:00',
    school: '',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: true,
    placeTime_yorn: false,
    placeTimeAddress_yorn: false,

    groomName_yorn: false,
    groomSurname_yorn: false,
    brideName_yorn: false,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: false,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: false,
    address_yorn: true,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: true,
    title_yorn: true,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 27,
    category: 'Party',
    subCategory: 'Graduation',
    img: HatsOffToYouItem,
    bg: HatsOffToYouBg,
    defaultImg: '',
    name: 'Hats Off To You',
    placeholder: '',
    placePlaceholder: 'School',
    age: '',
    price: 5500,
    title: '',
    phone: '',
    email: '',
    groomName: '',
    groomSurname: '',
    brideName: '',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: 'Emma',
    dateDay: '',
    dateNumber: '27',
    dateMonth: '05',
    dateYear: '2024',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: 'Place',
    placeTime: '13:00',
    address: '',
    addressTime: '',
    school: 'School',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: true,
    placeTimeAddress_yorn: false,
    groomName_yorn: false,
    groomSurname_yorn: false,
    brideName_yorn: false,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: true,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: true,
    address_yorn: false,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: false,
  },
  {
    id: 28,
    category: 'Party',
    subCategory: 'Cocktail',
    img: MixAndMingleItem,
    bg: MixAndMingleBg,
    defaultImg: MixAndMingleDefault,
    name: 'Mix & Mingle',
    placeholder: '',
    placePlaceholder: 'Place',
    age: '',
    price: 5500,
    title: '',
    phone: '',
    email: '',
    groomName: '',
    groomSurname: '',
    brideName: '',
    brideSurname: '',
    fatherName: '',
    motherName: '',
    husbandName: '',
    wifeName: '',
    personName: 'Mona',
    dateDay: '',
    dateNumber: '27',
    dateMonth: '05',
    dateYear: '2024',
    yearsAnniversary: '',
    churchName: '',
    churchAddress: '',
    churchTime: '',
    restaurantName: '',
    restaurantAddress: '',
    restaurantTime: '',
    time: '',
    place: 'Our Home Cheers',
    placeTime: '18:00',
    address: '',
    addressTime: '',
    school: 'School',
    schoolTime: '',
    city: '',
    // Boolean ===============================================
    time_yorn: false,
    restaurantTime_yorn: false,
    restaurantAddressTime_yorn: false,
    churchTime_yorn: false,
    churchTimeAddress_yorn: false,
    addressTime_yorn: false,
    placeTime_yorn: true,
    placeTimeAddress_yorn: false,
    groomName_yorn: false,
    groomSurname_yorn: false,
    brideName_yorn: false,
    brideSurname_yorn: false,
    fatherName_yorn: false,
    motherName_yorn: false,
    wifeName_yorn: false,
    husbandName_yorn: false,
    personName_yorn: true,
    age_yorn: false,
    restaurantName_yorn: false,
    churchName_yorn: false,
    restaurant_address_yorn: false,
    church_address_yorn: false,
    place_yorn: true,
    address_yorn: false,
    withTime_yorn: false,
    email_yorn: false,
    phone_yorn: false,
    yearsAnniversary_yorn: false,
    dragAndDrop_yorn: false,
    uploadImg_yorn: false,
    title_yorn: false,
    noEdit_yorn: false,
    wideWidth_yorn: false,
    longWide_yorn: true,
  },
];
export default cards;
