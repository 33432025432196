import styles from './style.module.css';
function Card({formData, isDateChanged}) {
  return (
    <div className={styles.card_wrapper}>
      <div className={styles.inner}>
        <h2 className={styles.title}>Let’s celebrate</h2>
        <p className={styles.text}>March 8</p>
        <div className={styles.bottom}>
          <div className={styles.date_time}>
            <span>{formData.dateNumber}</span>
            <span>
              {isDateChanged ? formData.dateMonthLong : formData.dateMonth},
            </span>
            <span>{formData.placeTime}</span>
          </div>
          <p className={styles.restaurant_text}>
            {formData.restaurantName} Restaurant
          </p>
        </div>
      </div>
    </div>
  );
}
export default Card;
