import styles from './style.module.css';

import PaymentImg from '../../assets/images/GlobalImages/payment-img.png'

const Payment = ({cardItem}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.inner_top}>
          <div>
            <span className={styles.text}>Card</span>
            <span className={styles.price}>{cardItem.price} amd</span>
          </div>
          <div>
            <span className={styles.text}>Print</span>
            <span className={styles.price}> 0 amd</span>
          </div>
        </div>
        <div className={styles.line}></div>
        <div className={styles.inner_bottom}>
          <div>
            <span className={styles.text}>Total</span>
            <span className={styles.price}>{cardItem.price} amd</span>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <img src={PaymentImg} alt="Payment" />
      </div>
    </div>
  );
};

export default Payment;
