import styles from './style.module.css';

function Card({formData, isDateChanged}) {
  return (
    <div
      className={styles.wrapper}
      // style={{backgroundImage: `url(${OurAnniversary})`}}
    >
      <div className={styles.inner}>
        <div className={styles.top_block}>
          <h2>
            You are invited
            <br /> Business Conference
          </h2>
        </div>

        <div className={styles.mid_block}>
          <div className={styles.mid_block_top}>
            <div className={styles.mid_left}>
              <span>
                {isDateChanged ? formData.dateDayLong : formData.dateDay}
              </span>
            </div>
            <div className={styles.mid_mid}>
              <span>
                {isDateChanged ? formData.dateMonthShort : formData.dateMonth}
              </span>
              <span>{formData.dateNumber}th</span>
              <span>
                {isDateChanged ? formData.dateYearLong : formData.dateYear}
              </span>
            </div>
            <div className={styles.mid_right}>
              <span>{formData.placeTime} pm</span>
            </div>
          </div>
          <div className={styles.mid_block_mid}>
            <p>{formData.title}</p>
          </div>

          <div className={styles.mid_block_bottom}>
            <span>{formData.address}</span>
            <span>{formData.city} CITY</span>
          </div>
        </div>

        <div className={styles.bottom_block}>
          <span>CONTATS</span>
          <span>{formData.phone}</span>
          <span>{formData.email}</span>
        </div>
      </div>
    </div>
  );
}
export default Card;
