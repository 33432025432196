import countries from './getCountries';
import styles from './style.module.css'

const FormSelect = () => {
  return (
    <select className={styles.select}>
      {countries.map((country) => {
        return <option key={country.name}>{country.name}</option>;
      })}
    </select>
  );
};

export default FormSelect;
