// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../assets/images/CardImages/CatHolidays/March8/wooman-day.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_card_wrapper__UAvzn {
  width: 510px;
  height: 665px;
  background: #eae2ec;
  box-shadow: 5px 5px 10px 0px #9a9a9a;
}
.style_inner__8qANO {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 25px;
  padding: 142px 0 93px;
}
.style_bottom__tqVDq {
  text-align: center;
  color: rgba(81, 17, 83, 0.76);
  font-family: 'Jaldi';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.style_title__WgJ2x {
  color: #959a5f;
  font-family: 'Jim Nightshade';
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.style_text__3ScyU {
  color: rgba(81, 17, 83, 0.76);
  text-shadow: 4px 5px 5px #e1d1e4;
  font-family: 'Jim Nightshade';
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 80px;
}
.style_date_time__Lbx-P {
  display: flex;
  gap: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CardsComponents/HolidayCards/CatMarch8/WoomenDay/style.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,oCAAoC;AACtC;AACA;EACE,yDAAkG;EAClG,YAAY;EACZ,4BAA4B;EAC5B,2BAA2B;EAC3B,qBAAqB;EACrB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,qBAAqB;AACvB;AACA;EACE,kBAAkB;EAClB,6BAA6B;EAC7B,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,6BAA6B;EAC7B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,6BAA6B;EAC7B,gCAAgC;EAChC,6BAA6B;EAC7B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,QAAQ;AACV","sourcesContent":[".card_wrapper {\n  width: 510px;\n  height: 665px;\n  background: #eae2ec;\n  box-shadow: 5px 5px 10px 0px #9a9a9a;\n}\n.inner {\n  background-image: url('../../../../../assets/images/CardImages/CatHolidays/March8/wooman-day.png');\n  height: 100%;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  row-gap: 25px;\n  padding: 142px 0 93px;\n}\n.bottom {\n  text-align: center;\n  color: rgba(81, 17, 83, 0.76);\n  font-family: 'Jaldi';\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 30px;\n}\n.title {\n  color: #959a5f;\n  font-family: 'Jim Nightshade';\n  font-size: 45px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 30px;\n}\n.text {\n  color: rgba(81, 17, 83, 0.76);\n  text-shadow: 4px 5px 5px #e1d1e4;\n  font-family: 'Jim Nightshade';\n  font-size: 80px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 80px;\n}\n.date_time {\n  display: flex;\n  gap: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card_wrapper": `style_card_wrapper__UAvzn`,
	"inner": `style_inner__8qANO`,
	"bottom": `style_bottom__tqVDq`,
	"title": `style_title__WgJ2x`,
	"text": `style_text__3ScyU`,
	"date_time": `style_date_time__Lbx-P`
};
export default ___CSS_LOADER_EXPORT___;
