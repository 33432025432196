import { useState } from 'react';
import styles from './style.module.css';
import SearchIco from '../../assets/icons/i-search.svg';
import SelectArrowDown from '../../assets/icons/menu-arrow-down.svg';
import SelectArrowUp from '../../assets/icons/menu-arrow-up.svg';

const Search = () => {
    const [src, setSrc] = useState(SelectArrowDown);
    const [showBlock, setShowBlock] = useState(false); 
    const [selectedOption, setSelectedOption] = useState('Website'); 

    const handleImgClick = () => {
        setSrc(src === SelectArrowUp ? SelectArrowDown : SelectArrowUp); 
        setShowBlock(!showBlock); 
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option); 
        setSrc(SelectArrowDown);
        setShowBlock(false); 
    };

    return (
            <div className={styles.search_wrapper}>
                <form id='search-form'>
                    <div className={styles.select}>
                        <span className={styles.category}>{selectedOption}</span>
                        <img src={src} onClick={handleImgClick} alt='arrow'/>
                        <div className={showBlock ? styles.cat_hidden : styles.cat} id="cat">
                            <div className={selectedOption === 'Website' ? styles.cat_title_selected : styles.cat_title} onClick={() => handleOptionClick('Website')}>Website</div>
                            <div className={selectedOption === 'Card' ? styles.cat_title_selected : styles.cat_title} onClick={() => handleOptionClick('Card')}>Card</div>
                        </div>
                    </div>
                    <div className={styles.search}>
                        <input type="text" placeholder='Search' />
                        <button type='button'>
                            <img src={SearchIco} alt="search" />
                        </button>
                    </div>
                </form>
            </div>
    );
};

export default Search;
