import styles from './style.module.css';
import OpeningGala from '../../../../../assets/images/CardImages/CatParty/Opening/opening-gala.png';
function Card({ formData, isDateChanged }) {
  return (
    <div
      className={styles.wrapper}
      style={{backgroundImage: `url(${OpeningGala})`}}
    >
      <div className={styles.inner}>
        <div className={styles.top_block}>
          <h2>You’re invited</h2>
          <p>to a</p>
        </div>
        <div className={styles.mid_block}>
          <p>{formData.title}</p>
        </div>
        <div className={styles.bottom_block}>
          <div className={styles.date_time}>
            <span>Date: </span>
            <span>
              {formData.dateNumber}.
              {isDateChanged ? formData.dateMonthNumber : formData.dateMonth}.
              {isDateChanged ? formData.dateYearLong : formData.dateYear}
            </span>
            <span>{formData.placeTime}</span>
          </div>
          <div className={styles.place}>
            <span>Place: </span>
            <span>{formData.place}</span>
          </div>
          <div className={styles.address}>
            <span>Address: </span>
            <span>{formData.address}</span>
          </div>
          <div className={styles.phone}>
            <span>Number: </span>
            <span>{formData.phone}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Card;
