// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__j5rN4 {
  background-size: cover;
  width: 510px;
  height: 665px;
  background-repeat: no-repeat;
  color: #2d1f0c;
  text-align: center;
  position: relative;
  box-shadow: 4px 4px 10px #454545;
}
.style_inner__mJYNU {
  color: #fcca87;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 38px;
  padding: 103px 0 135px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.style_top_text__1Oeip,
.style_text_and__m2kpB,
.style_bottom__lt2wp {
  font-family: 'Baskervville', sans-serif;
}
.style_mid__JhwNV {
  display: flex;
  flex-direction: column;
}
.style_groom_name__Xly2o,
.style_bride_name__5aFyb {
  font-family: 'Great Vibes', sans-serif;
  font-size: 78.773px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.style_number__P3GgQ {
  font-size: 64px;
  margin-right: 20px;
}
.style_day__t6RUc,
.style_month__Q0jLj,
.style_week__rVqh1,
.style_year__wC7ex {
  font-size: 21px;
  text-transform: uppercase;
}
.style_bottom_mid__MbBk2 {
  display: flex;
  align-items: center;
  column-gap: 36px;
  text-transform: uppercase;
}
.style_time__g-F31 {
  display: flex;
  column-gap: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CardsComponents/WeddingCards/CatWedding/Royal/style.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,4BAA4B;EAC5B,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,gCAAgC;AAClC;AACA;EACE,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;;;EAGE,uCAAuC;AACzC;AACA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;;EAEE,sCAAsC;EACtC,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,kBAAkB;AACpB;AACA;;;;EAIE,eAAe;EACf,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,eAAe;AACjB","sourcesContent":[".wrapper {\n  background-size: cover;\n  width: 510px;\n  height: 665px;\n  background-repeat: no-repeat;\n  color: #2d1f0c;\n  text-align: center;\n  position: relative;\n  box-shadow: 4px 4px 10px #454545;\n}\n.inner {\n  color: #fcca87;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  row-gap: 38px;\n  padding: 103px 0 135px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n.top_text,\n.text_and,\n.bottom {\n  font-family: 'Baskervville', sans-serif;\n}\n.mid {\n  display: flex;\n  flex-direction: column;\n}\n.groom_name,\n.bride_name {\n  font-family: 'Great Vibes', sans-serif;\n  font-size: 78.773px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n.number {\n  font-size: 64px;\n  margin-right: 20px;\n}\n.day,\n.month,\n.week,\n.year {\n  font-size: 21px;\n  text-transform: uppercase;\n}\n.bottom_mid {\n  display: flex;\n  align-items: center;\n  column-gap: 36px;\n  text-transform: uppercase;\n}\n.time {\n  display: flex;\n  column-gap: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__j5rN4`,
	"inner": `style_inner__mJYNU`,
	"top_text": `style_top_text__1Oeip`,
	"text_and": `style_text_and__m2kpB`,
	"bottom": `style_bottom__lt2wp`,
	"mid": `style_mid__JhwNV`,
	"groom_name": `style_groom_name__Xly2o`,
	"bride_name": `style_bride_name__5aFyb`,
	"number": `style_number__P3GgQ`,
	"day": `style_day__t6RUc`,
	"month": `style_month__Q0jLj`,
	"week": `style_week__rVqh1`,
	"year": `style_year__wC7ex`,
	"bottom_mid": `style_bottom_mid__MbBk2`,
	"time": `style_time__g-F31`
};
export default ___CSS_LOADER_EXPORT___;
