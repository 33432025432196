import styles from './style.module.css'

import FooterLogo from '../../assets/icons/footer-logo.svg'

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className="container">
                <div className={styles.wrapper}>
                    <p>&copy; Copyright 2024 All rights reserved</p>
                    <div className={styles.logo}>
                        <img src={FooterLogo} alt="InWorx" />
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer
