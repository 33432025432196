import styles from './style.module.css'; 
import { useNavigate } from 'react-router-dom';
import cards from '../../../data/cards';

import Search from '../../Search/Search';
import BreadCrambs from '../../BreadCrambs/BreadCrambs';

// const serverURL = 'sfjadkgjadkg'

const MainBusiness = () => {
  let routes = ['Home', 'Card', 'Business'];
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <Search />
      <div className="container">
        <BreadCrambs routes={routes} />
      </div>
      {/* <div className="container"> */}
      <div className={styles.inner}>
        <div className={styles.text_block}>
          <h2 className={styles.title}>Business Invitation</h2>
          <p className={styles.subtitle}>
            Choose a professional business meeting invite to send to all
            attendees.
          </p>
        </div>
      </div>
      <div className="container">
        <div className={styles.cards_block}>
          {cards
            .filter((item) => item.category === 'Business')
            .map((item) => {
              return (
                <div
                  key={item.id}
                  className={styles.card}
                  onClick={() => navigate(`/card/${item.id}`)}
                >
                  <div
                    className={styles.card_img}
                    // style={{
                    //   backgroundImage: `url(${serverURL}/images/${item.img})`,
                    // }}
                    style={{
                      backgroundImage: `url(${item.img})`,
                    }}
                  ></div>
                  <div className={styles.cards_info}>
                    <p>{item.name}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default MainBusiness;
