import styles from './style.module.css';

import CaptureWebsite from '../../assets/images/GlobalImages/website-img.png';
import CaptureCards from '../../assets/images/GlobalImages/card-img.png';
import ArrowRight from '../ArrowRight/ArrowRight';

const Categories = () => {
    return (
        <section className={styles.section}>
            <div className='container'>
                <div className={styles.wrapper}>
                    <div className={styles.left_img}>
                        <img src={CaptureWebsite} alt="Capture-website" />
                    </div>
                    <div className={styles.bottom_left}>
                        <span className={styles.title}>Website</span>
                        <ArrowRight />
                    </div>
                    <div className={styles.right_img}>
                        <img src={CaptureCards} alt="Capture-cards" />
                    </div>
                    <div className={styles.bottom_right}>
                        <span className={styles.title}>Cards</span>
                        <ArrowRight />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Categories
