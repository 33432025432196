
import MainWedding from "../components/MainComponents/MainWedding/MainWedding"

const Weddingpage = () => {
    return (
        <>
            <MainWedding />
        </>
    )
}

export default Weddingpage
