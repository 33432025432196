// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
.arrow {
    margin-right: 17px;
    text-decoration: none;
    display: inline-block;
    float: left;
    cursor: pointer;
    width: 50px;
    position: relative;
    height: 20px;
}

.arrow:before {
    content: '';
    height: 2px;
    width: 50px;
    background-color: #000;
    display: block;
    float: left;
    position: relative;
    top: 9px;
    transition: 0.2s ease-in-out;
}

.arrow:after {
    content: '';
    float: right;
    border-left: 11px solid #000;
    border-right: 11px solid transparent;
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    position: absolute;
}

.arrow:hover:before {
    width: 70px;
} */
.style_arrow_block__F9jjI {
    cursor: pointer;
    transition: all 0.3s ease;
    width: 60px;
}

.style_arrow_block__F9jjI .style_arrow__BBpfl {
    width: 100%;
    transition: all 0.3s ease;
}

.style_arrow_block__F9jjI:hover .style_arrow__BBpfl {
    width: 140%;
}
.style_arrow__BBpfl {
    margin-top: 1em;
    margin-right: 2em;
    border: 1px solid #000;
    position: relative;
    background: #000;
}
.style_arrow__BBpfl .style_head__HlR6D,
.style_arrow__BBpfl .style_head__HlR6D:after {
    border: 1.5px solid #000;
    border-radius: 2px;
    width: 12px;
    position: absolute;
    right: -5px;
    top: 2px;
    transform: rotate(-45deg);
}

.style_arrow__BBpfl .style_head__HlR6D:after {
    content: "";
    border-color: #000;
    right: -6px;
    top: -6px;
    transform: rotate(90deg);
}`, "",{"version":3,"sources":["webpack://./src/components/ArrowRight/style.module.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAoCG;AACH;IACI,eAAe;IACf,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,WAAW;AACf;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;;IAEI,wBAAwB;IACxB,kBAAkB;IAClB,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,wBAAwB;AAC5B","sourcesContent":["/* \n.arrow {\n    margin-right: 17px;\n    text-decoration: none;\n    display: inline-block;\n    float: left;\n    cursor: pointer;\n    width: 50px;\n    position: relative;\n    height: 20px;\n}\n\n.arrow:before {\n    content: '';\n    height: 2px;\n    width: 50px;\n    background-color: #000;\n    display: block;\n    float: left;\n    position: relative;\n    top: 9px;\n    transition: 0.2s ease-in-out;\n}\n\n.arrow:after {\n    content: '';\n    float: right;\n    border-left: 11px solid #000;\n    border-right: 11px solid transparent;\n    border-top: 11px solid transparent;\n    border-bottom: 11px solid transparent;\n    position: absolute;\n}\n\n.arrow:hover:before {\n    width: 70px;\n} */\n.arrow_block {\n    cursor: pointer;\n    transition: all 0.3s ease;\n    width: 60px;\n}\n\n.arrow_block .arrow {\n    width: 100%;\n    transition: all 0.3s ease;\n}\n\n.arrow_block:hover .arrow {\n    width: 140%;\n}\n.arrow {\n    margin-top: 1em;\n    margin-right: 2em;\n    border: 1px solid #000;\n    position: relative;\n    background: #000;\n}\n.arrow .head,\n.arrow .head:after {\n    border: 1.5px solid #000;\n    border-radius: 2px;\n    width: 12px;\n    position: absolute;\n    right: -5px;\n    top: 2px;\n    transform: rotate(-45deg);\n}\n\n.arrow .head:after {\n    content: \"\";\n    border-color: #000;\n    right: -6px;\n    top: -6px;\n    transform: rotate(90deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrow_block": `style_arrow_block__F9jjI`,
	"arrow": `style_arrow__BBpfl`,
	"head": `style_head__HlR6D`
};
export default ___CSS_LOADER_EXPORT___;
