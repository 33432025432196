import styles from './style.module.css';

import ArrowTop from '../../../assets/icons/time-picker_topArrow.svg';
import ArrowBottom from '../../../assets/icons/time-picker_bottomArrow.svg';
import TimePickerIco from '../../../assets/icons/time-picker.svg';
import {useState, useEffect} from 'react';

function TimePicker({ name, handleTimeInputChange }) {
  let hourNumbers = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '00',
  ];
  let minuteNumbers = [
    '05',
    '10',
    '15',
    '20',
    '25',
    '30',
    '35',
    '40',
    '45',
    '50',
    '55',
    '00',
  ];

  const [hour, setHour] = useState(hourNumbers[23]);
  const [minute, setMinute] = useState(minuteNumbers[11]);
  const [isActive, setIsActive] = useState(false);
  const [timeChanged, setTimeChanged] = useState(false);
  const [inputTimeField, setInputTimeField] = useState('');

  useEffect(() => {
    if (timeChanged) {
      setInputTimeField(hour + ':' + minute);
      handleTimeInputChange(hour + ':' + minute, name);
    }
  }, [timeChanged, hour, minute]);

  function displayBlock() {
    setIsActive(!isActive);
  }

  function handleClickHourPlus() {
    let currentHourIndex = hourNumbers.indexOf(hour);

    if (hourNumbers[currentHourIndex + 1] === undefined) {
      setHour(hourNumbers[0]);
    } else {
      setHour(hourNumbers[currentHourIndex + 1]);
    }

    setTimeChanged(true);
  }

  function handleClickHourMinus() {
    let currentHourIndex = hourNumbers.indexOf(hour);
    if (hourNumbers[currentHourIndex - 1] === undefined) {
      setHour(hourNumbers[hourNumbers.length - 1]);
    } else {
      setHour(hourNumbers[currentHourIndex - 1]);
    }

    setTimeChanged(true);
  }
  function handleClickMinutePlus() {
    let currentMinuteIndex = minuteNumbers.indexOf(minute);
    if (minuteNumbers[currentMinuteIndex + 1] === undefined) {
      setMinute(minuteNumbers[0]);
    } else {
      setMinute(minuteNumbers[currentMinuteIndex + 1]);
    }

    setTimeChanged(true);
  }
  function handleClickMinuteMinus() {
    let currentMinuteIndex = minuteNumbers.indexOf(minute);
    if (minuteNumbers[currentMinuteIndex - 1] === undefined) {
      setMinute(minuteNumbers[minuteNumbers.length - 1]);
    } else {
      setMinute(minuteNumbers[currentMinuteIndex - 1]);
    }

    setTimeChanged(true);
  }

  return (
    <div className={styles.picker_block}>
      <div className={styles.input_wrapper}>
        <input
          name={name}
          defaultValue={inputTimeField}
          type="text"
          placeholder="Time"
          id="time"
          className={styles.input}
          style={{pointerEvents: 'none'}}
        ></input>
        <img src={TimePickerIco} alt="" onClick={displayBlock} />
      </div>

      <div
        className={styles.picker_inner}
        style={isActive ? {display: 'flex'} : {display: 'none'}}
      >
        <div className={styles.left}>
          <div className={styles.top_arrow}>
            <img src={ArrowTop} alt="" onClick={handleClickHourPlus} />
          </div>
          <div className={styles.time_first_block}>{hour}</div>
          <div className={styles.bottom_arrow}>
            <img src={ArrowBottom} alt="" onClick={handleClickHourMinus} />
          </div>
        </div>
        <span>:</span>
        <div className={styles.right}>
          <div className={styles.top_arrow}>
            <img src={ArrowTop} alt="" onClick={handleClickMinutePlus} />
          </div>
          <div className={styles.time_second_block}>{minute}</div>
          <div className={styles.bottom_arrow}>
            <img src={ArrowBottom} alt="" onClick={handleClickMinuteMinus} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default TimePicker;
