import styles from './style.module.css'

// import ArrowRightIco from '../../assets/icons/arrow-right.svg'

const ArrowRight = () => {
  return (
    // <a href="" className={styles.arrow}></a>
    // {/* // <img src={ArrowRightIco} alt="Arrow-right" className={styles.arrow} /> */}
    <div className={styles.arrow_block}>
      <div className={styles.arrow}>
        <div className={styles.head}></div>
      </div>
    </div>

  )
}

export default ArrowRight
