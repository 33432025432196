import Navbar from "../Navbar/Navbar"
import styles from './styles.module.css'


const Header = () => {

  let locationHref = window.location.href;
  if (locationHref.indexOf('/card/') === -1) {
    localStorage.setItem('cardDetails', '')
  }

  return (
    <header className={styles.header}>
      <Navbar />
    </header>
  )
}

export default Header
