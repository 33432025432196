// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__0a\\+\\+G {
  width: 510px;
  height: 681px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: 4px 4px 4px 0px #595959;
}
.style_inner__wudiS {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 220px 0px 57px;
  color: #fff;
}
.style_title__9NgcN {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.style_title__9NgcN span:first-child {
  font-family: 'Jomhuria';
  font-size: 75px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.style_title__9NgcN span:nth-child(2) {
  font-family: 'Just Me Again Down Here';
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.style_bottom_block__vPjSm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.style_bottom_block__vPjSm span {
  font-family: 'Itim';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
`, "",{"version":3,"sources":["webpack://./src/components/CardsComponents/HolidayCards/Christmas/JingleMingle/style.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,4BAA4B;EAC5B,sBAAsB;EACtB,2BAA2B;EAC3B,mCAAmC;AACrC;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;AACb;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,sCAAsC;EACtC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".wrapper {\n  width: 510px;\n  height: 681px;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n  box-shadow: 4px 4px 4px 0px #595959;\n}\n.inner {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  padding: 220px 0px 57px;\n  color: #fff;\n}\n.title {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.title span:first-child {\n  font-family: 'Jomhuria';\n  font-size: 75px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n.title span:nth-child(2) {\n  font-family: 'Just Me Again Down Here';\n  font-size: 60px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n.bottom_block {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.bottom_block span {\n  font-family: 'Itim';\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__0a++G`,
	"inner": `style_inner__wudiS`,
	"title": `style_title__9NgcN`,
	"bottom_block": `style_bottom_block__vPjSm`
};
export default ___CSS_LOADER_EXPORT___;
