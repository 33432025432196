import {BrowserRouter, Routes, Route} from 'react-router-dom';

// Components =================================
import Layout from './Layout';
import HomePage from './pages/HomePage';
import Weddingpage from './pages/Weddingpage';
import BirthdayPage from './pages/BirthdayPage';
import PartyPage from './pages/PartyPage';
import BusinessPage from './pages/BusinessPage';
import HolidayPage from './pages/HolidayPage';

import Website from './components/WebsiteComponents/WeddingCards/CatEngagment/MagicalUnion/Website'

// Card, Edit, Payment pages ================================================
import CardPage from './pages/CardPage/CardPage';
import CardPaymentPage from './pages/CardPaymentPage/CardPaymentPage';
import CardEditPage from './pages/CardEditPage/CardEditPage';

function App() {
  return (
    <BrowserRouter>
      {/* <ScrollToTop /> */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="/wedding" element={<Weddingpage />} />
          <Route path="/birthday" element={<BirthdayPage />} />
          <Route path="/party" element={<PartyPage />} />
          <Route path="/business" element={<BusinessPage />} />
          <Route path="/holiday" element={<HolidayPage />} />
        </Route>

        <Route path="/card/edit/:id" element={<CardEditPage />} />
        <Route path="/card/payment/:id" element={<CardPaymentPage />} />
        <Route path="/card/:id" element={<CardPage />} />

        <Route path="/magical" element={<Website />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
