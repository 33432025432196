import styles from './style.module.css';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import cards from '../../data/cards';
import componentMap from '../../data/componentsMap';

// Global Components
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Search from '../../components/Search/Search';
import Form from '../../components/Form/Form';
import BreadCrambs from '../../components/BreadCrambs/BreadCrambs';

const CardEditPage = () => {
  const {id} = useParams();
  const cardItem = cards.find((item) => item.id === id);
  let cardDetails = null;
  if (localStorage.getItem('cardDetails')) {
    cardDetails = JSON.parse(localStorage.getItem('cardDetails'), true);
    if (cardDetails.id !== id) {
      localStorage.setItem('cardDetails', '');
    }
  }

  let cardRoutes = [
    'Home',
    cardItem.category,
    'Card',
    cardItem.subCategory,
    cardItem.name,
  ];

  const [formData, setFormData] = useState({
    id: cardDetails ? cardDetails.id : cardItem.id,
    defaultImg: cardDetails ? cardDetails.defaultImg : cardItem.defaultImg,
    groomName: cardDetails ? cardDetails.groomName : cardItem.groomName,
    brideName: cardDetails ? cardDetails.brideName : cardItem.brideName,
    groomSurname: cardDetails
      ? cardDetails.groomSurname
      : cardItem.groomSurname,
    brideSurname: cardDetails
      ? cardDetails.brideSurname
      : cardItem.brideSurname,
    fatherName: cardDetails ? cardDetails.fatherName : cardItem.fatherName,
    motherName: cardDetails ? cardDetails.motherName : cardItem.motherName,
    husbandName: cardDetails ? cardDetails.husbandName : cardItem.husbandName,
    wifeName: cardDetails ? cardDetails.wifeName : cardItem.wifeName,
    personName: cardDetails ? cardDetails.personName : cardItem.personName,
    age: cardDetails ? cardDetails.age : cardItem.age,
    churchName: cardDetails ? cardDetails.churchName : cardItem.churchName,
    churchAddress: cardDetails
      ? cardDetails.churchAddress
      : cardItem.churchAddress,
    churchTime: cardDetails ? cardDetails.churchTime : cardItem.churchTime,
    title: cardDetails ? cardDetails.title : cardItem.title,
    restaurantName: cardDetails
      ? cardDetails.restaurantName
      : cardItem.restaurantName,
    restaurantTime: cardDetails
      ? cardDetails.restaurantTime
      : cardItem.restaurantTime,
    restaurantAddress: cardDetails
      ? cardDetails.restaurantAddress
      : cardItem.restaurantAddress,
    address: cardDetails ? cardDetails.address : cardItem.address,
    addressTime: cardDetails ? cardDetails.addressTime : cardItem.addressTime,
    place: cardDetails ? cardDetails.place : cardItem.place,
    yearsAnniversary: cardDetails
      ? cardDetails.yearsAnniversary
      : cardItem.yearsAnniversary,
    placeTime: cardDetails ? cardDetails.placeTime : cardItem.placeTime,
    time: cardDetails ? cardDetails.time : cardItem.time,
    time12: cardDetails ? cardDetails.time12 : '',
    time24: cardDetails ? cardDetails.time24 : '',
    time12Hours: cardDetails ? cardDetails.time12Hours : '',
    time12Minutes: cardDetails ? cardDetails.time12Minutes : '',
    timePeriod: cardDetails ? cardDetails.timePeriod : '',
    date: cardDetails ? cardDetails.date : cardItem.date,
    dateDayLong: cardDetails ? cardDetails.dateDayLong : '',
    dateYearLong: cardDetails ? cardDetails.dateYearLong : '',
    dateMonthLong: cardDetails ? cardDetails.dateMonthLong : '',
    dateMonthShort: cardDetails ? cardDetails.dateMonthShort : '',
    dateMonthNumber: cardDetails ? cardDetails.dateMonthNumber : '',
    dateYearShort: cardDetails ? cardDetails.dateYearShort : '',
    dateDay: cardDetails ? cardDetails.dateDay : cardItem.dateDay,
    dateNumber: cardDetails ? cardDetails.dateNumber : cardItem.dateNumber,
    dateMonth: cardDetails ? cardDetails.dateMonth : cardItem.dateMonth,
    dateYear: cardDetails ? cardDetails.dateYear : cardItem.dateYear,
    email: cardDetails ? cardDetails.email : cardItem.email,
    phone: cardDetails ? cardDetails.phone : cardItem.phone,
    message: '',
    print: '',
    telephone: '',
  });

  const handleInputChange = (event) => {
    const {name, value} = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  function formatTime(time) {
    const [hours, minutes] = time.split(':').map(Number);

    // 24-hour format
    const time24 = `${String(hours).padStart(2, '0')}:${String(
      minutes
    ).padStart(2, '0')}`;

    // 12-hour format with AM/PM
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;
    const minutes12 = minutes < 10 ? '0' + minutes : minutes;
    const time12 = `${hours12}:${minutes12} ${period}`;

    return {
      time24,
      time12,
      time12Hours: `${hours12} ${period}`,
      time12Minutes: minutes12,
      timePeriod: period,
    };
  }

  const handleTimeInputChange = (time, name) => {
    const formattedTime = formatTime(time);
    setFormData((prevData) => ({
      ...prevData,
      [name]: time, // Update the original time
      time24: formattedTime.time24, // 24-hour format (e.g., "19:00")
      time12: formattedTime.time12, // 12-hour format with AM/PM (e.g., "7:00 PM")
      time12Hours: formattedTime.time12Hours, // Hours in 12-hour format (e.g., "7")
      time12Minutes: formattedTime.time12Minutes, // Minutes in 12-hour format (e.g., "00")
      timePeriod: formattedTime.timePeriod, // AM/PM period (e.g., "PM")
    }));
  };

  const handleDateChange = (date) => {
    // Extract specific parts from the date
    const dayOfWeek = date.toLocaleDateString('en-US', {weekday: 'long'});
    const dayOfMonth = String(date.getDate()).padStart(2, '0');
    const monthLong = date.toLocaleDateString('en-US', {month: 'long'}); // Full month name
    const monthShort = date.toLocaleDateString('en-US', {month: 'short'}); // Abbreviated month name
    const monthNumber = String(date.getMonth() + 1).padStart(2, '0'); // Month as a number (January is 0, so +1)
    const yearLong = date.getFullYear();
    const yearShort = date.getFullYear().toString().slice(-2); // Last two digits of the year

    // Update the form data
    setFormData((prevData) => ({
      ...prevData,
      date,
      dateDayLong: dayOfWeek, // e.g., "Monday"
      dateNumber: dayOfMonth, // e.g., 01
      dateMonthLong: monthLong, // e.g., "August"
      dateMonthShort: monthShort, // e.g., "Aug"
      dateMonthNumber: monthNumber, // e.g., 08
      dateYearLong: yearLong, // e.g., 2024
      dateYearShort: yearShort, // e.g., 24
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageUrl = reader.result;
        setFormData((prevData) => ({
          ...prevData,
          defaultImg: imageUrl, 
        }));
        setIsImageChanged(true);
      };
      reader.readAsDataURL(file); 
    }
  };

  const [isDateChanged, setIsDateChanged] = useState(false);
  const [isTimeChanged, setIsTimeChanged] = useState(false);
  const [isImageChanged, setIsImageChanged] = useState(false)
  const CardComponent = componentMap[cardItem.name];

  return (
    <div className={styles.content}>
      <Header />
      <div className={styles.wrapper}>
        <Search />
        <BreadCrambs routes={cardRoutes} />
        <div className="container">
          <div className={styles.inner}>
            <CardComponent
              formData={formData}
              cardDetails={cardDetails}
              setIsDateChanged={setIsDateChanged}
              setIsTimeChanged={setIsTimeChanged}
              isDateChanged={isDateChanged}
              isTimeChanged={isTimeChanged}
              isImageChanged={isImageChanged}
              setIsImageChanged={setIsImageChanged}
            />
            <Form
              cardID={id}
              cardItem={cardItem}
              formData={formData}
              cardDetails={cardDetails}
              handleInputChange={handleInputChange}
              handleDateChange={(date) => {
                setIsDateChanged(true);
                handleDateChange(date);
              }}
              handleTimeInputChange={(time, name) => {
                setIsTimeChanged(true);
                handleTimeInputChange(time, name);
              }}
              handleImageChange={handleImageChange}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CardEditPage;
