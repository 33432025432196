import styles from './style.module.css';

import ThankyouFirstImg from '../../../../assets/images/CardImages/CatThankYou/thankYou-1.png';

function Card({formData, isDateChanged}) {
  return (
    <div
      className={styles.wrapper}
      style={{backgroundImage: `url(${ThankyouFirstImg})`}}
    >
      <div className={styles.inner}>
        <h2 className={styles.title}>Thank You</h2>
        <p className={styles.text}>for attending our wedding</p>
        <p className={styles.name}>
          {formData.groomName} & {formData.brideName}
        </p>
        <div className={styles.date}>
          <span>{formData.dateNumber}/</span>
          <span>
            {isDateChanged ? formData.dateMonthNumber : formData.dateMonth}/
          </span>
          <span>
            {isDateChanged ? formData.dateYearLong : formData.dateYear}
          </span>
        </div>
      </div>
    </div>
  );
}
export default Card;
