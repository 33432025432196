import styles from './style.module.css';
import ChristmasParty from '../../../../../assets/images/CardImages/CatHolidays/Christmas/christmas-party.png';
function Card({formData, isDateChanged, isTimeChanged}) {
  return (
    <div
      className={styles.wrapper}
      style={{backgroundImage: `url(${ChristmasParty})`}}
    >
      <div className={styles.inner}>
        <div className={styles.title}>
          <span>CHRISTMAS PARTY</span>
          <span>of</span>
          <span>{formData.personName}</span>
        </div>
        <div className={styles.bottom_block}>
          <div className={styles.date}>
            <span>
              {isDateChanged ? formData.dateMonthShort : formData.dateMonth}
            </span>
            |<span>{formData.dateNumber}</span>|
            <span>
              {isTimeChanged
                ? formData.placeTime.split(':')[1] === '00'
                  ? formData.time12Hours
                  : formData.time12
                : formData.placeTime + ' PM'}
            </span>
          </div>
          <span>{formData.place}</span>
          <span>{formData.address}</span>
        </div>
      </div>
    </div>
  );
}
export default Card;
