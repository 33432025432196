import styles from './style.module.css';
import Img from '../../../../../assets/images/GlobalImages/divine-blessing-img.png';

function Card({formData, isDateChanged}) {
  return (
    <div
      className={styles.wrapper}
      //   style={{backgroundImage: `url(${GirlyGetAway})`}}
    >
      <div className={styles.inner}>
        <h2 className={styles.title}>You are invited to christening of</h2>
        <p className={styles.name}>{formData.personName}</p>
        <img src={Img} alt="" />
        <div className={styles.bottom_block}>
          <div className={styles.date_time}>
            <span>
              {isDateChanged ? formData.dateMonthLong : formData.dateMonth}
            </span>
            <span>{formData.dateNumber}th</span>
            <span>{formData.churchTime}</span>
          </div>
          <div className={styles.place}>{formData.churchName} Monastery</div>
        </div>
      </div>
    </div>
  );
}
export default Card;
