import styles from './style.module.css';
import AdventureParty from '../../../../../assets/images/CardImages/CatBirthday/Kids/adventure-party.png';

function Card({formData, isDateChanged}) {
  return (
    <div
      className={styles.wrapper}
      style={{backgroundImage: `url(${AdventureParty})`}}
    >
      <div className={styles.inner}>
        <div className={styles.top_text}>We are celebrating!</div>
        <div className={styles.age_block}>{formData.age} years of</div>
        <div className={styles.name}>{formData.personName}</div>
        <div className={styles.date_time}>
          <span>{formData.dateNumber}.</span>
          <span>
            {isDateChanged ? formData.dateMonthNumber : formData.dateMonth}.
          </span>
          <span>
            {isDateChanged ? formData.dateYearShort : formData.dateYear}
          </span>
          <span style={{ marginLeft: '10px' }}>{formData.placeTime}</span>
        </div>
        <div className={styles.place_addr}>
          <span>{formData.place}</span>
          <span>{formData.address}</span>
        </div>
        <div className={styles.bottom_text}>JOIN US!</div>
      </div>
    </div>
  );
}
export default Card;
