import styles from './style.module.css'

import Web4 from '../../assets/images/cardWebsitesImages/web-inv4.png'
import Card4 from '../../assets/images/cardWebsitesImages/card-inv4.png'

const BusinessDrop = () => {
    return (
      <div className={styles.drop_wrapper}>
        <ul className={styles.drop_list}>
          <li>
            <a href="#">Meeting</a>
          </li>
          <li>
            <a href="#">Art Exhibition</a>
          </li>
          <li>
            <a href="#">Busines Anniversary</a>
          </li>
          <li>
            <a href="#">Conference</a>
          </li>
          <li>
            <a href="#">Seminar</a>
          </li>
        </ul>
        <ul className={styles.drop_list}>
          <li>
            <a href="#">Staff party</a>
          </li>
        </ul>
        <div className={styles.drop_right}>
          <div className={styles.img_web}>
            <img src={Web4} alt="business-website" />
            <span>Website</span>
          </div>
          <div className={styles.img_card}>
            <img src={Card4} alt="business-card" />
            <span>Card</span>
          </div>
        </div>
      </div>
    );
}

export default BusinessDrop
