// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__9niev {
  width: 750px;
  height: 500px;
  padding: 25px;
  background: #fff;
  box-shadow: 4px 4px 10px 0px #c6c6c6;
}
.style_inner__lb3cX {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  border: 1px solid #767676;

  padding: 54px 0 47px;
}
.style_title__OkTMi {
  color: #698a7d;
  font-family: 'Crimson Text';
  font-size: 36px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}
.style_name__\\+E3KA {
  color: #586a63;
  font-family: 'Montserrat';
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.style_bottom_block__P6DPO {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 36px;
  align-items: center;
  color: #698a7d;
  font-family: 'Crimson Text';
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}
.style_date_time__SrsZx {
  display: flex;
  gap: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CardsComponents/PartyCards/Christenity/DivineBlessing/style.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,gBAAgB;EAChB,oCAAoC;AACtC;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,8BAA8B;EAC9B,YAAY;EACZ,yBAAyB;;EAEzB,oBAAoB;AACtB;AACA;EACE,cAAc;EACd,2BAA2B;EAC3B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,eAAe;EACf,mBAAmB;EACnB,cAAc;EACd,2BAA2B;EAC3B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,QAAQ;AACV","sourcesContent":[".wrapper {\n  width: 750px;\n  height: 500px;\n  padding: 25px;\n  background: #fff;\n  box-shadow: 4px 4px 10px 0px #c6c6c6;\n}\n.inner {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  height: 100%;\n  border: 1px solid #767676;\n\n  padding: 54px 0 47px;\n}\n.title {\n  color: #698a7d;\n  font-family: 'Crimson Text';\n  font-size: 36px;\n  font-style: italic;\n  font-weight: 400;\n  line-height: normal;\n}\n.name {\n  color: #586a63;\n  font-family: 'Montserrat';\n  font-size: 64px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n.bottom_block {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  padding: 0 36px;\n  align-items: center;\n  color: #698a7d;\n  font-family: 'Crimson Text';\n  font-size: 24px;\n  font-style: italic;\n  font-weight: 400;\n  line-height: normal;\n}\n.date_time {\n  display: flex;\n  gap: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__9niev`,
	"inner": `style_inner__lb3cX`,
	"title": `style_title__OkTMi`,
	"name": `style_name__+E3KA`,
	"bottom_block": `style_bottom_block__P6DPO`,
	"date_time": `style_date_time__SrsZx`
};
export default ___CSS_LOADER_EXPORT___;
