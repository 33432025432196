import ForeverTogether from '../components/CardsComponents/WeddingCards/CatWedding/ForeverTogether/Card'
import Bliss from '../components/CardsComponents/WeddingCards/CatWedding/Bliss/Card';
import Royal from '../components/CardsComponents/WeddingCards/CatWedding/Royal/Card';
import BloomOfLove from '../components/CardsComponents/WeddingCards/CatEngagment/BloomsOfLove/Card';
import QueenForADay from '../components/CardsComponents/BirthdayCards/CatWooman/QueenOfLove/Card';
import WomenDay from '../components/CardsComponents/HolidayCards/CatMarch8/WoomenDay/Card';
import WeWelcomeYou from '../components/CardsComponents/PartyCards/CatGender/WeWelcomeYou/Card';
import StartOfUs from '../components/CardsComponents/WeddingCards/CatWedding/StartOfUs/Card';
import LoveUnites from '../components/CardsComponents/WeddingCards/CatWedding/LoveUnites/Card';
import NewChapter from '../components/CardsComponents/WeddingCards/CatEngagment/NewChapter/Card';
import ThankYouFirst from '../components/CardsComponents/ThankYouCards/ThankYouFirst/Card';
import ThankYouSecond from '../components/CardsComponents/ThankYouCards/ThankyouSecond/Card';
import ThankYouThird from '../components/CardsComponents/ThankYouCards/ThankYouThird/Card';
import OurAnniversary from '../components/CardsComponents/WeddingCards/CatAnniversary/OurAnniversary/Card';
import ConferenceCall from '../components/CardsComponents/BusinessCards/CatConference/ConferenceCall/Card';
import GirlyGetAway from '../components/CardsComponents/PartyCards/CatGirlsParty/GirlyGetAway/Card';
import GranddadBirthday from '../components/CardsComponents/BirthdayCards/CatMan/GranddadBirthday/Card';
import HolidayCheer from '../components/CardsComponents/HolidayCards/Christmas/HolidayCheer/Card';
import ChristmasParty from '../components/CardsComponents/HolidayCards/Christmas/ChristmasParty/Card';
import JingleMingle from '../components/CardsComponents/HolidayCards/Christmas/JingleMingle/Card';
import AdventureParty from '../components/CardsComponents/BirthdayCards/CatKids/AdventureParty/Card';
import BroBash from '../components/CardsComponents/PartyCards/CatBoys/BroBash/Card';
import OpeningGala from '../components/CardsComponents/PartyCards/CatOpening/OpeningGala/Card';
import TimelessLove from '../components/CardsComponents/WeddingCards/CatEngagment/TimelessLove/Card';
import DivineBlessing from '../components/CardsComponents/PartyCards/Christenity/DivineBlessing/Card';
import VogueVoyage from '../components/CardsComponents/PartyCards/CatOpening/VogueVoyage/Card';
import HatsOffToYou from '../components/CardsComponents/PartyCards/CatGraduation/HatsOffToYou/Card'
import MixAndMingle from '../components/CardsComponents/PartyCards/CatCocktail/MixAndMingle/Card';

// Create the componentMap object
const componentMap = {
  'Forever Together': ForeverTogether,
  Bliss: Bliss,
  Royal: Royal,
  'Blooms of Love': BloomOfLove,
  'Queen for a Day': QueenForADay,
  'Women Day': WomenDay,
  'We Welcome You': WeWelcomeYou,
  'Start of us': StartOfUs,
  'Love unites': LoveUnites,
  'A New Chapter': NewChapter,
  'Thank You 1': ThankYouFirst,
  'Thank You 2': ThankYouSecond,
  'Thank You 3': ThankYouThird,
  'Our Anniversary': OurAnniversary,
  'Conference Call': ConferenceCall,
  'Girly getaway': GirlyGetAway,
  'Granddad Birthday': GranddadBirthday,
  'Holiday Cheer': HolidayCheer,
  'Christmas party': ChristmasParty,
  'Jingle Mingle': JingleMingle,
  'Adventure Party': AdventureParty,
  'Bro Bash': BroBash,
  'Opening Gala': OpeningGala,
  'Timeless Love': TimelessLove,
  'Divine Blessing': DivineBlessing,
  'Vogue Voyage': VogueVoyage,
  'Hats Off To You': HatsOffToYou,
  'Mix & Mingle': MixAndMingle,
};

export default componentMap;
