import Categories from "../components/Categories/Categories"
import Celebrate from "../components/Celebrate/Celebrate"
import Invitations from "../components/Invitations/Invitations"
import OnlineCard from '../components/OnlineCard/OnlineCard';
import Search from "../components/Search/Search";



const HomePage = () => {
  return (
    <>
      <Search />
      <Celebrate />
      <Invitations />
      <Categories />
      <OnlineCard />
    </>
  );
}

export default HomePage
