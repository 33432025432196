// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_section__t9S-A {
    width: 100%;
    height: 374px;
    background: #E8E8E8;
    margin-top: 330px;
}

.style_title__9Qcxr {
    color: #111;
    font-family: "Brygada 1918" sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
}

.style_wrapper__erYjl {
    padding: 40px 0;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.style_inner__JR1dl {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.style_left_side__znOFU,
.style_right_side__2h3G2 {
    flex-direction: column;
}

.style_mid_side__JozSk {
    margin-top: -225px;
}
.style_capture2__Fo1Ba, .style_capture3__BMa7y, .style_capture4__DKm6x {
    width: 285px;
    height: 400px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/Invitations/style.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,sCAAsC;IACtC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;;IAEI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,aAAa;IACb,4BAA4B;IAC5B,2BAA2B;IAC3B,sBAAsB;AAC1B","sourcesContent":[".section {\n    width: 100%;\n    height: 374px;\n    background: #E8E8E8;\n    margin-top: 330px;\n}\n\n.title {\n    color: #111;\n    font-family: \"Brygada 1918\" sans-serif;\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 500;\n}\n\n.wrapper {\n    padding: 40px 0;\n    display: flex;\n    justify-content: space-evenly;\n    width: 100%;\n}\n\n.inner {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.left_side,\n.right_side {\n    flex-direction: column;\n}\n\n.mid_side {\n    margin-top: -225px;\n}\n.capture2, .capture3, .capture4 {\n    width: 285px;\n    height: 400px;\n    background-repeat: no-repeat;\n    background-position: center;\n    background-size: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `style_section__t9S-A`,
	"title": `style_title__9Qcxr`,
	"wrapper": `style_wrapper__erYjl`,
	"inner": `style_inner__JR1dl`,
	"left_side": `style_left_side__znOFU`,
	"right_side": `style_right_side__2h3G2`,
	"mid_side": `style_mid_side__JozSk`,
	"capture2": `style_capture2__Fo1Ba`,
	"capture3": `style_capture3__BMa7y`,
	"capture4": `style_capture4__DKm6x`
};
export default ___CSS_LOADER_EXPORT___;
