import styles from './style.module.css';
import JingleMingle from '../../../../../assets/images/CardImages/CatHolidays/Christmas/jingle-mingle.png';
function Card({formData, isDateChanged}) {
  return (
    <div
      className={styles.wrapper}
      style={{backgroundImage: `url(${JingleMingle})`}}
    >
      <div className={styles.inner}>
        <div className={styles.title}>
          <span>Let’s celebrate</span>
          <span>Christmas</span>
        </div>
        <div className={styles.bottom_block}>
          <span>{formData.placeTime}</span>
          <span>
            {formData.dateNumber}.
            {isDateChanged ? formData.dateMonthNumber : formData.dateMonth}.
            {isDateChanged ? formData.dateYearLong : formData.dateYear}
          </span>
          <span>{formData.place}</span>
        </div>
      </div>
    </div>
  );
}
export default Card;
