// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_footer__vxcNY {
  background: #6f5d44;
  width: 100%;
}
.style_footer__vxcNY p {
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.style_footer__vxcNY p,
.style_logo__UIoOr {
  flex: 1 1;
}
.style_wrapper__MYIUj {
  display: flex;
  width: 100%;
  height: 102px;
  align-items: center;
  padding: 0 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/style.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,WAAW;EACX,gCAAgC;EAChC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;AACA;;EAEE,SAAO;AACT;AACA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".footer {\n  background: #6f5d44;\n  width: 100%;\n}\n.footer p {\n  color: #fff;\n  font-family: 'Inter', sans-serif;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n}\n.footer p,\n.logo {\n  flex: 1;\n}\n.wrapper {\n  display: flex;\n  width: 100%;\n  height: 102px;\n  align-items: center;\n  padding: 0 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `style_footer__vxcNY`,
	"logo": `style_logo__UIoOr`,
	"wrapper": `style_wrapper__MYIUj`
};
export default ___CSS_LOADER_EXPORT___;
