import styles from './style.module.css';

import Web2 from '../../assets/images/cardWebsitesImages/web-inv2.png';
import Card2 from '../../assets/images/cardWebsitesImages/card-inv2.png';

const BirthdayDrop = () => {
  return (
    <div className={styles.drop_wrapper}>
      <ul className={styles.drop_list}>
        <li>
          <a href="#">Kids</a>
        </li>
        <li>
          <a href="#">Woman</a>
        </li>
        <li>
          <a href="#">Man</a>
        </li>
        <li>
          <a href="#">Animal</a>
        </li>
      </ul>
      <div className={styles.drop_right}>
        <div className={styles.img_web}>
          <img src={Web2} alt="birthday-website" />
          <span>Website</span>
        </div>
        <div className={styles.img_card}>
          <img src={Card2} alt="birthday-card" />
          <span>Card</span>
        </div>
      </div>
    </div>
  );
};

export default BirthdayDrop;
