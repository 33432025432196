import styles from './style.module.css';
import {useNavigate} from 'react-router-dom';
import cards from '../../../data/cards';

import Search from '../../Search/Search';
import BreadCrambs from '../../BreadCrambs/BreadCrambs';


const MainBirthday = () => {
  const navigate = useNavigate();
  let routes = ['Home', 'Card', 'Birthday'];
  return (
    <>
      <div className={styles.wrapper}>
        <Search />
        <div className="container">
          <BreadCrambs routes={routes} />
        </div>
        <div className={styles.inner}>
          <div className={styles.text_block}>
            <h2 className={styles.title}>Birthday invitation</h2>
            <p className={styles.subtitle}>
              Choose the ideal birthday invite to share with all your friends
              and family.
            </p>
          </div>
        </div>
        <div className="container">
          <div className={styles.cards_block}>
            {cards
              .filter((item) => item.category === 'Birthday')
              .map((item) => {
                return (
                  <div
                    key={item.id}
                    className={styles.card}
                    onClick={() => navigate(`/card/${item.id}`)}
                  >
                    <div
                      className={styles.card_img}
                      style={{backgroundImage: `url(${item.img})`}}
                    ></div>
                    <div className={styles.cards_info}>
                      <p>{item.name}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainBirthday;
