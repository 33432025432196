import BirthdayDrop from './BirthdayDrop';
import BusinessDrop from './BusinessDrop';
import HolidayDrop from './HolidayDrop';
import PartyDrop from './PartyDrop';
import WeddingDrop from './WeddingDrop';
import styles from './style.module.css';

const Wrapper = ({ activeTab, setActiveTab }) => {

    return (
        <div className={styles.container} onMouseOver={() => {
            setActiveTab('')
        }}>
            <div className={styles.wrapper} id="activeTab" onMouseOver={(e) => {e.stopPropagation()}}>
                {activeTab === 'Wedding' && <WeddingDrop />}
                {activeTab === 'Birthday' && <BirthdayDrop />}
                {activeTab === 'Party' && <PartyDrop />}
                {activeTab === 'Business' && <BusinessDrop />}
                {activeTab === 'Holiday' && <HolidayDrop />}
            </div>
        </div>
    )
}

export default Wrapper;
