import MainParty from "../components/MainComponents/MainParty/MainParty"

const PartyPage = () => {
    return (
        <>
            <MainParty/>
        </>
    )
}

export default PartyPage
