// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__NjXH\\+ {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  row-gap: 25px;
  /* background: #eee3ce36;
  opacity: 21;
  width: 50%; */
}
.style_top__lrJYP {
  max-width: 500px;
  width: 100%;
  height: 194px;
  border-radius: 10px;
  background: #f3ebdc;
  box-shadow: 0px 4px 4px 0px #d3cdc5 inset;
  padding: 36px;
}
.style_inner_top__83x7D,
.style_inner_bottom__vaJDR {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.style_inner_top__83x7D > div,
.style_inner_bottom__vaJDR > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.style_text__Q1Ghb,
.style_price__UkCjO {
  color: #000;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}
.style_inner_top__83x7D.style_text__Q1Ghb {
  font-weight: 500;
}
.style_inner_top__83x7D.style_price__UkCjO {
  font-weight: 600;
}
.style_inner_bottom__vaJDR {
  font-weight: 700;
}
.style_line__sDcs1 {
  background: #6f5d44;
  width: 100%;
  height: 1px;
  margin: 26px 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Payment/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,aAAa;EACb;;eAEa;AACf;AACA;EACE,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,yCAAyC;EACzC,aAAa;AACf;AACA;;EAEE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;AACA;;EAEE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;;EAEE,WAAW;EACX,qCAAqC;EACrC,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,WAAW;EACX,WAAW;EACX,cAAc;AAChB","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  /* align-items: center; */\n  row-gap: 25px;\n  /* background: #eee3ce36;\n  opacity: 21;\n  width: 50%; */\n}\n.top {\n  max-width: 500px;\n  width: 100%;\n  height: 194px;\n  border-radius: 10px;\n  background: #f3ebdc;\n  box-shadow: 0px 4px 4px 0px #d3cdc5 inset;\n  padding: 36px;\n}\n.inner_top,\n.inner_bottom {\n  display: flex;\n  flex-direction: column;\n  row-gap: 20px;\n}\n.inner_top > div,\n.inner_bottom > div {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.text,\n.price {\n  color: #000;\n  font-family: 'Montserrat', sans-serif;\n  font-size: 16px;\n  font-style: normal;\n  line-height: normal;\n}\n.inner_top.text {\n  font-weight: 500;\n}\n.inner_top.price {\n  font-weight: 600;\n}\n.inner_bottom {\n  font-weight: 700;\n}\n.line {\n  background: #6f5d44;\n  width: 100%;\n  height: 1px;\n  margin: 26px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__NjXH+`,
	"top": `style_top__lrJYP`,
	"inner_top": `style_inner_top__83x7D`,
	"inner_bottom": `style_inner_bottom__vaJDR`,
	"text": `style_text__Q1Ghb`,
	"price": `style_price__UkCjO`,
	"line": `style_line__sDcs1`
};
export default ___CSS_LOADER_EXPORT___;
