// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_content__NbYlW {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.style_wrapper__toOso {
    background-size: cover;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.style_edit_btn__yjJI7 {
    width: 147px;
    height: 43px;
    position: fixed;
    top: 103px;
    right: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    box-shadow: 4px 4px 4px #6F5D44;
}`, "",{"version":3,"sources":["webpack://./src/pages/CardPage/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,aAAa;AACjB;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,4BAA4B;IAC5B,2BAA2B;IAC3B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,UAAU;IACV,QAAQ;IACR,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,qCAAqC;IACrC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,+BAA+B;AACnC","sourcesContent":[".content {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    height: 100vh;\n}\n\n.wrapper {\n    background-size: cover;\n    width: 100%;\n    height: 100%;\n    background-repeat: no-repeat;\n    background-position: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n}\n\n.edit_btn {\n    width: 147px;\n    height: 43px;\n    position: fixed;\n    top: 103px;\n    right: 0;\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: #000;\n    font-family: 'Montserrat', sans-serif;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 500;\n    box-shadow: 4px 4px 4px #6F5D44;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `style_content__NbYlW`,
	"wrapper": `style_wrapper__toOso`,
	"edit_btn": `style_edit_btn__yjJI7`
};
export default ___CSS_LOADER_EXPORT___;
