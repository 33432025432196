import styles from './style.module.css';

import NewChapterImg from '../../../../../assets/images/CardImages/CatWedding/engagment/new-chapter.png';

function Card({formData, isDateChanged}) {
  return (
    <div
      className={styles.wrapper}
      style={{backgroundImage: `url(${NewChapterImg})`}}
    >
      <div className={styles.inner}>
        <div className={styles.top}>
          <p>
            The honour of your presence is
            <br /> requested at the engagement of{' '}
          </p>
        </div>
        <div className={styles.mid}>
          <div className={styles.mid_top}>
            <span className={styles.name}>{formData.brideName}</span>
            <span className={styles.surname}>{formData.brideSurname}</span>
          </div>
          <div className={styles.mid_mid}>
            <span></span>
            <span>&</span>
            <span></span>
          </div>
          <div className={styles.mid_bottom}>
            <span className={styles.name}>{formData.groomName}</span>
            <span className={styles.surname}>{formData.groomSurname}</span>
          </div>
        </div>
        <div className={styles.bottom}>
          <div>
            <span>{formData.address},</span>
            <span>{formData.restaurantName} Restaurant</span>
          </div>
          <div>
            <span>
              {isDateChanged ? formData.dateDayLong : formData.dateDay},
            </span>
            <span>{formData.dateNumber}</span>
            <span>
              {isDateChanged ? formData.dateMonthLong : formData.dateMonth}
            </span>
            <span>
              {isDateChanged ? formData.dateYearLong : formData.dateYear}
            </span>
          </div>
          <div className={styles.time}>{formData.placeTime}</div>
        </div>
      </div>
    </div>
  );
}
export default Card;
