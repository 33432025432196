import styles from './style.module.css';

import BloomOfLoveImg from '../../../../../assets/images/CardImages/CatWedding/engagment/blooms-of-love.png';

function Card({formData, cardDetails, isDateChanged}) {
  return (
    <div
      className={styles.wrapper}
      style={{backgroundImage: `url(${BloomOfLoveImg})`}}
    >
      <div className={styles.inner}>
        <div className={styles.top}>
          <div className={styles.date_time}>
            <span className={styles.month}>
              {isDateChanged || cardDetails
                ? formData.dateMonthLong
                : formData.dateMonth}
            </span>
            <span className={styles.number}>{formData.dateNumber}</span>
            <span className={styles.time}>
              {isDateChanged || cardDetails
                ? formData.restaurantTime
                : formData.restaurantTime}
            </span>
          </div>
          <p className={styles.restaurant_text}>
            {formData.restaurantName} Restaurant
          </p>
        </div>
        <p className={styles.title}>Please join us to celebrate</p>
        <div className={styles.name_block}>
          <span className={styles.bride_name}>{formData.brideName}</span>
          <span className={styles.and_text}>&</span>
          <span className={styles.groom_name}>{formData.groomName}</span>
        </div>
        <p className={styles.bottom_text}>Engagement Party</p>
      </div>
    </div>
  );
}
export default Card;
