
import MainBirthday from "../components/MainComponents/MainBirthday/MainBirthday"


const BirthdayPage = () => {
    return (
        <>
            <MainBirthday />
        </>
    )
}

export default BirthdayPage
