import styles from './style.module.css';

function Card({formData, isDateChanged}) {
  return (
    <div
      className={styles.wrapper}
      //   style={{backgroundImage: `url(${WeWelcomeYouImg})`}}
    >
      <div className={styles.inner}>
        <div style={{backgroundImage: `url(${formData.defaultImg})`}} className={styles.default_img}></div>
        <div className={styles.right_block}>
          <h2 className={styles.title}>You’re Invited! 🎉</h2>
          <p className={styles.text}>
            Come and join us for an evening of fun, laughter, and great
            memories.
          </p>
          <div className={styles.bottom_block}>
            <span className={styles.date}>
              {formData.dateNumber}.
              {isDateChanged ? formData.dateMonthNumber : formData.dateMonth}.
              {isDateChanged ? formData.dateYearLong : formData.dateYear}
            </span>
            <span className={styles.place}>{formData.place}</span>
            <span className={styles.name}>{formData.personName}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Card;
