import styles from './style.module.css';
import HolidayCheer from '../../../../../assets/images/CardImages/CatHolidays/Christmas/holiday-cheer.png'
function Card({formData, isDateChanged}) {
  return (
    <div
      className={styles.wrapper}
      style={{backgroundImage: `url(${HolidayCheer})`}}
    >
      <div className={styles.inner}>
        <div className={styles.title}>
          <span>You are invited to a</span>
          <span>Christmas party</span>
        </div>
        <div className={styles.bottom_block}>
          <span>
            {formData.dateNumber}.
            {isDateChanged ? formData.dateMonthNumber : formData.dateMonth}.
            {isDateChanged ? formData.dateYearLong : formData.dateYear}
          </span>
          <span>{formData.placeTime}</span>
          <span>{formData.place}</span>
        </div>
      </div>
    </div>
  );
}
export default Card;

