import {useState} from 'react';
import styles from './style.module.css';
import prevArrow from '../../assets/icons/prev-btn.svg';
import ContinueArrow from '../../assets/icons/cont-btn.svg';
import { useNavigate } from 'react-router-dom';

const FormButtons = ({text, formData, isStaylesPrev, isStaylesCont, path}) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    if (text === 'preview') {
      localStorage.setItem(
        'cardDetails',
        JSON.stringify({
          id: formData.id,
          defaultImg: formData.defaultImg,
          title: formData.title,
          age: formData.age,
          email: formData.email,
          phone: formData.phone,
          message: '',
          print: '',
          telephone: '',
          yearsAnniversary: formData.yearsAnniversary,
          groomName: formData.groomName,
          brideName: formData.brideName,
          groomSurname: formData.groomSurname,
          brideSurname: formData.brideSurname,
          fatherName: formData.fatherName,
          motherName: formData.motherName,
          husbandName: formData.husbandName,
          wifeName: formData.wifeName,
          personName: formData.personName,
          churchName: formData.churchName,
          churchTime: formData.churchTime,
          churchAddress: formData.churchAddress,
          restaurantName: formData.restaurantName,
          restaurantTime: formData.restaurantTime,
          restaurantAddress: formData.restaurantAddress,
          place: formData.place,
          placeTime: formData.placeTime,
          address: formData.address,
          addressTime: formData.addressTime,

          time: formData.time,
          time12: formData.time12,
          time24: formData.time24,
          time12Hours: formData.time12Hours,
          time12Minutes: formData.time12Minutes,
          timePeriod: formData.timePeriod,

          date: formData.date,
          dateDay: formData.dateDay,
          dateDayLong: formData.dateDayLong,
          dateNumber: formData.dateNumber,
          dateMonth: formData.dateMonth,
          dateYear: formData.dateYear,
          dateMonthLong: formData.dateMonthLong,
          dateMonthShort: formData.dateMonthShort,
          dateMonthNumber: formData.dateMonthNumber,
          dateYearShort: formData.dateYearShort,
          dateYearLong: formData.dateYearLong,
        })
      );
    }
    navigate(path);
  };

  let btnStyles;
  let imgUrl;
  if (isStaylesPrev) {
    btnStyles = styles.preview_btn;
    imgUrl = prevArrow;
  } else if (isStaylesCont) {
    btnStyles = styles.continue_btn;
    imgUrl = ContinueArrow;
  }
  return (
    <button
      type="button"
      className={btnStyles}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <img
        src={imgUrl}
        alt="more-arrow"
        className={`${styles.hover_arrow} ${
          isHovered ? styles.arrow_hovered : ''
        }`}
      />
      <span
        className={`${styles.hover_text} ${
          isHovered ? styles.text_hovered : ''
        }`}
      >
        {text}
      </span>
    </button>
  );
};

export default FormButtons;
