// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_section__DRyOO {
    width: 100%;
    margin-top: 66px;
    margin-bottom: 105px;
}

.styles_wrapper__UeAo3 {
    max-width: 1242px;
    width: 100%;
    background: #E8E8E8;
    display: flex;
    justify-content: space-around;
    height: 374px;
    padding: 0 5px;
}

.styles_left__B3Tja {
    display: flex;
    flex-direction: column;
    color: #6F5D44;
    font-family: 'Montserrat', sans-serif;
    max-width: 550px;
    padding: 50px 0;
}

.styles_title__HIr9L {
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
}

.styles_subtitle__T3mWE {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin: 32px 0 53px;
}

.styles_line__F0d5R {
    width: 1px;
    height: 100%;
    background: #6F5D44;
}

.styles_right__ls42i {
    display: flex;
    gap: 16px;
    padding: 30px 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/OnlineCard/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,6BAA6B;IAC7B,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;IACd,qCAAqC;IACrC,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,eAAe;AACnB","sourcesContent":[".section {\n    width: 100%;\n    margin-top: 66px;\n    margin-bottom: 105px;\n}\n\n.wrapper {\n    max-width: 1242px;\n    width: 100%;\n    background: #E8E8E8;\n    display: flex;\n    justify-content: space-around;\n    height: 374px;\n    padding: 0 5px;\n}\n\n.left {\n    display: flex;\n    flex-direction: column;\n    color: #6F5D44;\n    font-family: 'Montserrat', sans-serif;\n    max-width: 550px;\n    padding: 50px 0;\n}\n\n.title {\n    font-size: 64px;\n    font-style: normal;\n    font-weight: 400;\n}\n\n.subtitle {\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 400;\n    margin: 32px 0 53px;\n}\n\n.line {\n    width: 1px;\n    height: 100%;\n    background: #6F5D44;\n}\n\n.right {\n    display: flex;\n    gap: 16px;\n    padding: 30px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `styles_section__DRyOO`,
	"wrapper": `styles_wrapper__UeAo3`,
	"left": `styles_left__B3Tja`,
	"title": `styles_title__HIr9L`,
	"subtitle": `styles_subtitle__T3mWE`,
	"line": `styles_line__F0d5R`,
	"right": `styles_right__ls42i`
};
export default ___CSS_LOADER_EXPORT___;
