// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_section__f\\+0y3 {
    width: 100%;
    display: flex;
    background: #EEE3CE;
    padding-bottom: 30px;
    height: 500px;
    margin-top: 600px;
}

.style_wrapper__zxr-0 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
}

/* .left,
.right {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
} */

.style_left_img__8e0i9 {
    position: absolute;
    top: -265px;
    left: 0;
    max-width: 510px;
    width: 100%;
    overflow: hidden;
}

.style_right_img__ievHv {
    position: absolute;
    top: -265px;
    right: 0;
    max-width: 510px;
    width: 100%;
    overflow: hidden;
}

.style_bottom_left__zk8v7,
.style_bottom_right__5exx6 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: max-content;
    position: absolute;
    bottom: 0;
    width: 500px;
}

.style_bottom_left__zk8v7 {
    left: 0;
}

.style_bottom_right__5exx6 {
    right: 0;
}

.style_title__j7fGu {
    /* margin-left: 17px; */
    color: #111;
    font-family: "Brygada 1918", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/Categories/style.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,oBAAoB;IACpB,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;;;;;;GAMG;;AAEH;IACI,kBAAkB;IAClB,WAAW;IACX,OAAO;IACP,gBAAgB;IAChB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,gBAAgB;IAChB,WAAW;IACX,gBAAgB;AACpB;;AAEA;;IAEI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,OAAO;AACX;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,uBAAuB;IACvB,WAAW;IACX,uCAAuC;IACvC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".section {\n    width: 100%;\n    display: flex;\n    background: #EEE3CE;\n    padding-bottom: 30px;\n    height: 500px;\n    margin-top: 600px;\n}\n\n.wrapper {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    position: relative;\n}\n\n/* .left,\n.right {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    row-gap: 30px;\n} */\n\n.left_img {\n    position: absolute;\n    top: -265px;\n    left: 0;\n    max-width: 510px;\n    width: 100%;\n    overflow: hidden;\n}\n\n.right_img {\n    position: absolute;\n    top: -265px;\n    right: 0;\n    max-width: 510px;\n    width: 100%;\n    overflow: hidden;\n}\n\n.bottom_left,\n.bottom_right {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    height: max-content;\n    position: absolute;\n    bottom: 0;\n    width: 500px;\n}\n\n.bottom_left {\n    left: 0;\n}\n\n.bottom_right {\n    right: 0;\n}\n\n.title {\n    /* margin-left: 17px; */\n    color: #111;\n    font-family: \"Brygada 1918\", sans-serif;\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `style_section__f+0y3`,
	"wrapper": `style_wrapper__zxr-0`,
	"left_img": `style_left_img__8e0i9`,
	"right_img": `style_right_img__ievHv`,
	"bottom_left": `style_bottom_left__zk8v7`,
	"bottom_right": `style_bottom_right__5exx6`,
	"title": `style_title__j7fGu`
};
export default ___CSS_LOADER_EXPORT___;
