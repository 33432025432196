import styles from './style.module.css';
import BroBash from '../../../../../assets/images/CardImages/CatParty/Guy/bro-bash.png';

function Card({formData, isDateChanged}) {
  return (
    <div
      className={styles.wrapper}
      style={{backgroundImage: `url(${BroBash})`}}
    >
      <div className={styles.inner}>
        <div className={styles.top_block}>
          <h2 className={styles.title}>Guy’s party</h2>
          <span>
            {formData.dateNumber}.
            {isDateChanged ? formData.dateMonthLong : formData.dateMonth}
          </span>
        </div>
        <div className={styles.mid_block}>
          <div className={styles.mid_block_top}>
            <div className={styles.date}>
              <span>
                {isDateChanged ? formData.dateMonthLong : formData.dateMonth}
              </span>
              <span>{formData.dateNumber},</span>
              <span>
                {isDateChanged ? formData.dateYearLong : formData.dateYear}
              </span>
            </div>
            <span>{formData.placeTime} PM</span>
          </div>

          <div className={styles.mid_block_bottom}>
            <span>{formData.address}</span>
            <span>{formData.place}</span>
          </div>
        </div>
        <div className={styles.bottom_block}>
          <p className={styles.text}>
            looking forward
            <br /> to celebrate with you!
          </p>
        </div>
      </div>
    </div>
  );
}
export default Card;
