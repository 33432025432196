
import MainHoliday from "../components/MainComponents/MainHoliday/MainHoliday"

const HolidayPage = () => {
    return (
        <>
            <MainHoliday />
        </>
    )
}

export default HolidayPage
