import styles from './style.module.css';
import LoveUnitesImg from '../../../../../assets/images/CardImages/CatWedding/wedding/love-unites.png';

function Card({formData, isDateChanged}) {
  return (
    <div
      className={styles.wrapper}
      style={{backgroundImage: `url(${LoveUnitesImg})`}}
    >
      <div className={styles.inner}>
        <p className={styles.date}>
          <span>{formData.dateNumber}</span>.
          <span>
            {isDateChanged ? formData.dateMonthNumber : formData.dateMonth}
          </span>
          .
          <span>
            {isDateChanged ? formData.dateYearShort : formData.dateYear}
          </span>
        </p>
        <div className={styles.names}>
          <span>{formData.brideName}</span>
          <span>&</span>
          <span>{formData.groomName}</span>
        </div>
        <p className={styles.text}>WEDDING</p>
      </div>
    </div>
  );
}
export default Card;
