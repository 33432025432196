// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__M-C4c {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  margin-top: 80px;
}

.style_wrapper__pRJEb {
  background: #fff;
  box-shadow: 4px 4px 4px 0px #dadada;
  width: 721px;
  height: 267px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  padding: 10px;
  font-weight: 400;
  margin: auto;
}
.style_drop_wrapper__PjjJF {
  display: flex;
  justify-content: space-between;
  padding: 0 40px 50px;
  align-items: center;
}
.style_drop_list__IyEqL {
  list-style: none;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  height: 200px;
}
.style_drop_list__IyEqL a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  padding-bottom: 3px;
}
.style_drop_list__IyEqL a:hover {
  font-weight: 500;
  border-bottom: 1px solid #574327;
}
.style_drop_right__A7fvq {
  display: flex;
  gap: 18px;
}
.style_img_web__WeItx,
.style_img_card__OMQcc {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  cursor: pointer;
}
.style_img_web__WeItx > span,
.style_img_card__OMQcc > span {
  position: absolute;
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/NavbarComponents/style.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mCAAmC;EACnC,YAAY;EACZ,aAAa;EACb,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,oBAAoB;EACpB,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,aAAa;AACf;AACA;EACE,qBAAqB;EACrB,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,qBAAqB;EACrB,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,gCAAgC;AAClC;AACA;EACE,aAAa;EACb,SAAS;AACX;AACA;;EAEE,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;AACjB;AACA;;EAEE,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 999;\n  margin-top: 80px;\n}\n\n.wrapper {\n  background: #fff;\n  box-shadow: 4px 4px 4px 0px #dadada;\n  width: 721px;\n  height: 267px;\n  color: #000;\n  font-size: 16px;\n  font-style: normal;\n  padding: 10px;\n  font-weight: 400;\n  margin: auto;\n}\n.drop_wrapper {\n  display: flex;\n  justify-content: space-between;\n  padding: 0 40px 50px;\n  align-items: center;\n}\n.drop_list {\n  list-style: none;\n  display: flex;\n  flex-direction: column;\n  row-gap: 15px;\n  height: 200px;\n}\n.drop_list a {\n  text-decoration: none;\n  color: #000;\n  font-size: 16px;\n  font-weight: 400;\n  display: inline-block;\n  padding-bottom: 3px;\n}\n.drop_list a:hover {\n  font-weight: 500;\n  border-bottom: 1px solid #574327;\n}\n.drop_right {\n  display: flex;\n  gap: 18px;\n}\n.img_web,\n.img_card {\n  position: relative;\n  display: flex;\n  flex-direction: column-reverse;\n  align-items: center;\n  cursor: pointer;\n}\n.img_web > span,\n.img_card > span {\n  position: absolute;\n  color: #000;\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 400;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__M-C4c`,
	"wrapper": `style_wrapper__pRJEb`,
	"drop_wrapper": `style_drop_wrapper__PjjJF`,
	"drop_list": `style_drop_list__IyEqL`,
	"drop_right": `style_drop_right__A7fvq`,
	"img_web": `style_img_web__WeItx`,
	"img_card": `style_img_card__OMQcc`
};
export default ___CSS_LOADER_EXPORT___;
