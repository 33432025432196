// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__cJj3- {
  width: 510px;
  height: 665px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.style_inner__CyeVo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.style_names__su5xz {
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-weight: 800;
  margin: 21px 0 33px 0;
  line-height: 134.014%;
  letter-spacing: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.style_date__yupOW {
  color: #000;
  font-family: 'Proza Libre', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 134.014%;
  letter-spacing: 3px;
}

.style_text__ziKV4 {
  color: #ffffff;
  font-family: 'Proza Libre', sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 134.014%;
  letter-spacing: 9px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CardsComponents/WeddingCards/CatWedding/LoveUnites/style.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,4BAA4B;EAC5B,2BAA2B;EAC3B,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,kCAAkC;EAClC,eAAe;EACf,gBAAgB;EAChB,qBAAqB;EACrB,qBAAqB;EACrB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,sCAAsC;EACtC,eAAe;EACf,gBAAgB;EAChB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,sCAAsC;EACtC,eAAe;EACf,gBAAgB;EAChB,qBAAqB;EACrB,mBAAmB;AACrB","sourcesContent":[".wrapper {\n  width: 510px;\n  height: 665px;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.inner {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.names {\n  color: #ffffff;\n  font-family: 'Poppins', sans-serif;\n  font-size: 40px;\n  font-weight: 800;\n  margin: 21px 0 33px 0;\n  line-height: 134.014%;\n  letter-spacing: 6px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.date {\n  color: #000;\n  font-family: 'Proza Libre', sans-serif;\n  font-size: 20px;\n  font-weight: 700;\n  line-height: 134.014%;\n  letter-spacing: 3px;\n}\n\n.text {\n  color: #ffffff;\n  font-family: 'Proza Libre', sans-serif;\n  font-size: 30px;\n  font-weight: 700;\n  line-height: 134.014%;\n  letter-spacing: 9px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__cJj3-`,
	"inner": `style_inner__CyeVo`,
	"names": `style_names__su5xz`,
	"date": `style_date__yupOW`,
	"text": `style_text__ziKV4`
};
export default ___CSS_LOADER_EXPORT___;
