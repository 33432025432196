import styles from './style.module.css';

import Web3 from '../../assets/images/cardWebsitesImages/web-inv3.png';
import Card3 from '../../assets/images/cardWebsitesImages/card-inv3.png';

const PartyDrop = () => {
  return (
    <div className={styles.drop_wrapper}>
      <ul className={styles.drop_list}>
        <li>
          <a href="#">Gender party</a>
        </li>
        <li>
          <a href="#">Graduation</a>
        </li>
        <li>
          <a href="#">Girl’s party</a>
        </li>
        <li>
          <a href="#">Guy’s party</a>
        </li>
        <li>
          <a href="#">Picnic</a>
        </li>
      </ul>
      <ul className={styles.drop_list}>
        <li>
          <a href="#">Christening</a>
        </li>
        <li>
          <a href="#">First tooth</a>
        </li>
        <li>
          <a href="#">Cocktail party</a>
        </li>
        <li>
          <a href="#">New home</a>
        </li>
        <li>
          <a href="#">Fashion show</a>
        </li>
      </ul>
      <div className={styles.drop_right}>
        <div className={styles.img_web}>
          <img src={Web3} alt="party-website" />
          <span>Website</span>
        </div>
        <div className={styles.img_card}>
          <img src={Card3} alt="party-card" />
          <span>Card</span>
        </div>
      </div>
    </div>
  );
};

export default PartyDrop;
