// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets//images/GlobalImages/wedding-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__OsLCD {
  width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.style_inner__57wnw {
  position: relative;
  margin-bottom: 73px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
}
/* .inner img {
  width: 100%;
} */
.style_text_block__lx2ci {
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  top: 92px;
  right: 250px;
}

.style_title__gV9QQ {
  color: #4a3923;
  font-family: 'Poly', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}

.style_subtitle__pArGi {
  color: #4a3923;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  max-width: 345px;
}
.style_cards_block__u2V5D {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1270px;
  justify-content: flex-start;
  column-gap: 50px;
  row-gap: 25px;
}
.style_card_img__MCJSL {
  width: 280px;
  height: 404px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.style_cards_info__V3weF p {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/MainComponents/MainWedding/style.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,yDAA0E;EAC1E,2BAA2B;EAC3B,sBAAsB;EACtB,4BAA4B;EAC5B,WAAW;EACX,aAAa;AACf;AACA;;GAEG;AACH;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,SAAS;EACT,YAAY;AACd;;AAEA;EACE,cAAc;EACd,+BAA+B;EAC/B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,qCAAqC;EACrC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,eAAe;EACf,WAAW;EACX,iBAAiB;EACjB,2BAA2B;EAC3B,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,4BAA4B;EAC5B,eAAe;AACjB;AACA;EACE,eAAe;AACjB","sourcesContent":[".wrapper {\n  width: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.inner {\n  position: relative;\n  margin-bottom: 73px;\n  background-image: url(../../../assets//images/GlobalImages/wedding-bg.png);\n  background-position: center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  width: 100%;\n  height: 300px;\n}\n/* .inner img {\n  width: 100%;\n} */\n.text_block {\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  row-gap: 20px;\n  top: 92px;\n  right: 250px;\n}\n\n.title {\n  color: #4a3923;\n  font-family: 'Poly', sans-serif;\n  font-size: 40px;\n  font-style: normal;\n  font-weight: 400;\n}\n\n.subtitle {\n  color: #4a3923;\n  font-family: 'Montserrat', sans-serif;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  max-width: 345px;\n}\n.cards_block {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  max-width: 1270px;\n  justify-content: flex-start;\n  column-gap: 50px;\n  row-gap: 25px;\n}\n.card_img {\n  width: 280px;\n  height: 404px;\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  cursor: pointer;\n}\n.cards_info p {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__OsLCD`,
	"inner": `style_inner__57wnw`,
	"text_block": `style_text_block__lx2ci`,
	"title": `style_title__gV9QQ`,
	"subtitle": `style_subtitle__pArGi`,
	"cards_block": `style_cards_block__u2V5D`,
	"card_img": `style_card_img__MCJSL`,
	"cards_info": `style_cards_info__V3weF`
};
export default ___CSS_LOADER_EXPORT___;
