
import {useState} from 'react';
import styles from './style.module.css';

import ContinueArrow from '../../assets/icons/cont-btn.svg';
import FormSelect from '../Form/FormSelect';

const FormPayment = ({cardItem}) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };



  const [cardInfo, setCardInfo] = useState({
    cardNumber: '',
    cardHolderName: '',
    expireDate: '',
    cvv: '',
  });

  const formatCreditCardNumber = (input) => {
    const cleanedInput = input.replace(/\D/g, '');

    let formattedInput = '';
    for (let i = 0; i < cleanedInput.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedInput += ' ';
      }
      formattedInput += cleanedInput[i];
    }

    return formattedInput;
  };

  const formatCardHolderName = (input) => {
    const cleanedInput = input.replace(/[^a-zA-Z\s]/g, '');

    let formattedName = '';
    for (let i = 0; i < cleanedInput.length; i++) {
      formattedName += cleanedInput[i];
    }

    return formattedName.toUpperCase();
  };

  const formatExpireDate = (input) => {
    let cleanedInputExpDate = input.replace(/\D/g, '');

    let formattedExpireDate = '';
    for (let i = 0; i < cleanedInputExpDate.length; i++) {
      if (i > 0 && i % 2 === 0) {
        formattedExpireDate += '/';
      }
      formattedExpireDate += cleanedInputExpDate[i];
    }
    return formattedExpireDate;
  };

  const formatCvv = (input) => {
    const cleanedInputCvv = input.replace(/\D/g, '');
    let formattedCvv = '';
    for (let i = 0; i < cleanedInputCvv.length; i++) {
      formattedCvv += cleanedInputCvv[i];
    }
    return formattedCvv;
  };

  const [errors, setErrors] = useState({});

  const validateCardNumber = (e) => {
    if (cardInfo.cardNumber === '') {
      return 'Please provide a valid 16-digit card number.';
    } else if (cardInfo.cardNumber.length < 18) {
      return 'The card number should have at least 16 digits.';
    }
    return '';
  };

  const validateCardHolderName = (e) => {
    if (cardInfo.cardHolderName === '') {
      return "Please enter the cardholder's name.";
    }
    return '';
  };

  const validateExpireDate = (e) => {
    if (cardInfo.expireDate === '') {
      return 'Please enter a valid MM/YY expiration date.';
    } else if (
      cardInfo.expireDate.length < 5 ||
      (parseInt(cardInfo.expireDate.split('/')[0]) <
        new Date().getMonth() + 1 &&
        parseInt(cardInfo.expireDate.split('/')[1]) ===
          new Date().getFullYear() % 100) ||
      parseInt(cardInfo.expireDate.split('/')[1]) <
        new Date().getFullYear() % 100
    ) {
      return 'Please use a valid and unexpired card';
    } else if (parseInt(cardInfo.expireDate.split('/')[0]) > 12) {
      return 'Please provide a valid month between 01 and 12.';
    }
    return '';
  };

  const validateCvv = (e) => {
    if (cardInfo.cvv === '' || cardInfo.cvv.length < 3) {
      return 'Please enter a valid 3-digit CVV code.';
    }
    return '';
  };

  const handleInputChange = (field, value) => {
    const newValues = {...cardInfo, [field]: value};
    if (field === 'cardNumber') {
      newValues.cardNumber = formatCreditCardNumber(value);
    }

    if (field === 'expireDate') {
      newValues.expireDate = formatExpireDate(value);
    }
    if (field === 'CVV') {
      newValues.cvv = formatCvv(value);
    }
    if (field === 'cardHolderName') {
      newValues.cardHolderName = formatCardHolderName(value);
    }
    setCardInfo(newValues);
  };

  const payNow = () => {
    const validationErrors = {
      cardNumber: validateCardNumber('cardNumber', cardInfo.cardNumber),
      cardHolderName: validateCardHolderName(
        'cardHolderName',
        cardInfo.cardHolderName
      ),
      expireDate: validateExpireDate('expireDate', cardInfo.expireDate),
      cvv: validateCvv('cvv', cardInfo.cvv),
    };

    if (Object.values(validationErrors).some((error) => error !== '')) {
      setErrors(validationErrors);
      return;
    } else {
      //   navigate('/bill');
    }
  };

  const errorStyles = {
    color: '#FF0000',
    fontSize: 10,
    fontFamily: 'Inter',
    fontWeight: '400',
    wordWrap: 'break-word',
    paddingLeft: '7px',
  };

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Payment information</h2>
      <form className={styles.form} action="">
        <div>
          <input
            type="text"
            name=""
            id="text_cardNumber"
            className={styles.input}
            placeholder="Card Number"
            value={cardInfo.cardNumber}
            maxLength={19}
            onChange={(e) => handleInputChange('cardNumber', e.target.value)}
          />
          {errors.cardHolderName && (
            <p style={errorStyles}>{errors.cardNumber}</p>
          )}
        </div>
        <div>
          <input
            type="text"
            name=""
            id="text_nameSurname"
            className={styles.input}
            placeholder="Name Surname"
            value={cardInfo.cardHolderName}
            onChange={(e) =>
              handleInputChange('cardHolderName', e.target.value)
            }
          />
          <p style={errorStyles}>{errors.cardHolderName}</p>
        </div>
        <div className={styles.input_block}>
          <div style={{width: '50%'}}>
            <input
              type="text"
              name=""
              id="text_date"
              className={styles.input}
              placeholder="MM/YY"
              maxLength={5}
              value={cardInfo.expireDate}
              onChange={(e) => handleInputChange('expireDate', e.target.value)}
            />
            <p style={errorStyles}>{errors.expireDate}</p>
          </div>
          <div style={{width: '50%'}}>
            <input
              type="text"
              name=""
              id="text_cvv"
              className={styles.input}
              placeholder="CVV"
              maxLength={3}
              value={cardInfo.cvv}
              onChange={(e) => handleInputChange('CVV', e.target.value)}
            />
            <p style={errorStyles}>{errors.cvv}</p>
          </div>
        </div>
        <FormSelect />
        <button
          type="button"
          className={styles.btn}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={payNow}
        >
          <img
            src={ContinueArrow}
            alt="more-arrow"
            className={`${styles.hover_arrow} ${
              isHovered ? styles.arrow_hovered : ''
            }`}
          />
          <span
            className={`${styles.hover_text} ${
              isHovered ? styles.text_hovered : ''
            }`}
          >
            Pay {cardItem.price}
          </span>
        </button>
      </form>
    </div>
  );
};

export default FormPayment;
