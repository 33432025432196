import styles from './style.module.css'

import Web1 from '../../assets/images/cardWebsitesImages/web-inv1.png'
import Card1 from '../../assets/images/cardWebsitesImages/card-inv1.png';

const WeddingDrop = () => {
    return (
      <div className={styles.drop_wrapper}>
        <ul className={styles.drop_list}>
          <li>
            <a href="#">Wedding</a>
          </li>
          <li>
            <a href="#">Engagement</a>
          </li>
          <li>
            <a href="#">Anniversary</a>
          </li>
          <li>
            <a href="#">Bride to be</a>
          </li>
          <li>
            <a href="#">Thank you</a>
          </li>
        </ul>
        <div className={styles.drop_right}>
          <div className={styles.img_web}>
            <img src={Web1} alt="wedding-website" />
            <span>Website</span>
          </div>
          <div className={styles.img_card}>
            <img src={Card1} alt="wedding-card" />
            <span>Card</span>
          </div>
        </div>
      </div>
    );
}

export default WeddingDrop
