import styles from './style.module.css';
import GranddadBirthday from '../../../../../assets/images/CardImages/CatBirthday/Man/granddad-birthday.png';
import Tree from '../../../../../assets/images/GlobalImages/tree-grandpa-birthday.png';

function Card({ formData, cardDetails, isDateChanged }) {
  console.log(cardDetails);
  return (
    <div
      className={styles.wrapper}
      style={{backgroundImage: `url(${GranddadBirthday})`}}
    >
      <div className={styles.inner}>
        <div className={styles.top_block}>
          <h2 className={styles.title}>
            Grandpa {formData.personName}’s birthday
          </h2>
          <p className={styles.top_text}>
            "Our Grandparents are a blessing to this generation and a great
            inspiration to us who have interacted with them"
          </p>
        </div>
        <div className={styles.img_block}>
          <img src={Tree} alt="Tree" />
        </div>
        <div className={styles.bottom_block}>
          <p className={styles.bottom_text}>
            It’s a great joy in our heart to invite you our grandparent Mike’s
            birthday. So mark your calendar, and save the date.
          </p>

          <div className={styles.bottom_block_bottom}>
            <div className={styles.place_addr}>
              <span>{formData.place}</span>
              <span>{formData.address}</span>
            </div>

            <div className={styles.line}></div>
            <div className={styles.date_day}>
              <span>
                {isDateChanged || cardDetails
                  ? cardDetails.dateDayLong
                  : formData.dateDay}
              </span>
              <span>{isDateChanged ? formData.dateNumber : formData.dateNumber}</span>
              <span>
                {isDateChanged || cardDetails
                  ? cardDetails.dateMonthLong
                  : formData.dateMonth}
                ,
                {isDateChanged || cardDetails
                  ? cardDetails.dateYearLong
                  : formData.dateYear}
              </span>
            </div>
            <div className={styles.line}></div>
            <span className={styles.time}>{formData.placeTime}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Card;
