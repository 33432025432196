// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__3Shgb {
  width: 927px;
  height: 606px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 4px 8px 0px #C2C2C2;
}
.style_inner__faKjy {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 42px;
}
.style_names__lJ-5R {
  color: #000;
  font-family: 'Alex Brush', sans-serif;
  font-size: 64px;
  font-weight: 400;
}
.style_date__PS4Rh {
  color: #000;
  font-family: 'Arvo', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 8.24px;
  display: flex;
  column-gap: 10px;
}
.style_title__YcSCe {
  color: #000;
  font-family: 'Arvo', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 8.24px;
}
.style_text__EWVvy {
  color: #000;
  text-align: center;
  font-family: 'Arvo', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CardsComponents/WeddingCards/CatWedding/StartOfUs/style.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,4BAA4B;EAC5B,2BAA2B;EAC3B,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mCAAmC;AACrC;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,WAAW;EACX,qCAAqC;EACrC,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,+BAA+B;EAC/B,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,+BAA+B;EAC/B,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,+BAA+B;EAC/B,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;AACvB","sourcesContent":[".wrapper {\n  width: 927px;\n  height: 606px;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  box-shadow: 4px 4px 8px 0px #C2C2C2;\n}\n.inner {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  row-gap: 42px;\n}\n.names {\n  color: #000;\n  font-family: 'Alex Brush', sans-serif;\n  font-size: 64px;\n  font-weight: 400;\n}\n.date {\n  color: #000;\n  font-family: 'Arvo', sans-serif;\n  font-size: 16px;\n  font-weight: 700;\n  line-height: normal;\n  letter-spacing: 8.24px;\n  display: flex;\n  column-gap: 10px;\n}\n.title {\n  color: #000;\n  font-family: 'Arvo', sans-serif;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: normal;\n  letter-spacing: 8.24px;\n}\n.text {\n  color: #000;\n  text-align: center;\n  font-family: 'Arvo', sans-serif;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: normal;\n  letter-spacing: 4.8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__3Shgb`,
	"inner": `style_inner__faKjy`,
	"names": `style_names__lJ-5R`,
	"date": `style_date__PS4Rh`,
	"title": `style_title__YcSCe`,
	"text": `style_text__EWVvy`
};
export default ___CSS_LOADER_EXPORT___;
