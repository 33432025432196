import styles from './style.module.css';
import React, { useState } from 'react';

import MoreArrow from '../../assets/icons/more-arrow.svg'
import { useNavigate } from 'react-router-dom';

const ButtonMore = ({ forCelebrate,path }) => {

    let btn;
    if (forCelebrate) {
        btn = styles.forCelebrate_btn
    } else {
        btn = styles.btn
    }

    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate()


    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const handleClick = () => {
        navigate(path)
    };

    return (
        <button className={btn} type='button'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}>
            <img src={MoreArrow} alt="more-arrow" className={`${styles.hover_arrow} ${isHovered ? styles.arrow_hovered : ''}`} />
            <span className={`${styles.hover_text} ${isHovered ? styles.text_hovered : ''}`}>more</span>
        </button>
    )
}

export default ButtonMore
