import styles from './style.module.css';
import WeWelcomeYouImg from '../../../../../assets/images/CardImages/CatParty/Gender/we-welcome-you-card-bg.png';
import BorderImg from '../../../../../assets/images/borderImages/wee-welcome-border.svg';
import BabyImg from '../../../../../assets/images/GlobalImages/baby.png'

function Card({formData, isDateChanged}) {
  return (
    <div
      className={styles.card_wrapper}
      style={{backgroundImage: `url(${WeWelcomeYouImg})`}}
    >
      <div className={styles.inner}>
        <div className={styles.top}>
          <p className={styles.top_suptitle}>We are inviting you to the</p>
          <h2 className={styles.title}>
            <span>Gender</span>
            <span>Party</span>
          </h2>
          <p className={styles.top_subtitle}>in honor of</p>
        </div>
        <div className={styles.mid}>
          <div className={styles.img_block}>
            <img src={BabyImg} alt="Baby" />
            <div
              className={styles.img_border}
              style={{backgroundImage: `url(${BorderImg})`}}
            >
              <div
                className={styles.img}
                style={{
                  backgroundImage: `url(${formData.defaultImg})`
                }}
              ></div>
            </div>
          </div>
          <p className={styles.names_text}>
            {formData.fatherName}
            <br /> <span>&</span>
            <br /> {formData.motherName}
          </p>
        </div>
        <div className={styles.bottom}>
          <div className={styles.date_text}>
            <span>Date:</span>
            <span>
              {formData.dateNumber}.
              {isDateChanged ? formData.dateMonthNumber : formData.dateMonth}.
              {isDateChanged ? formData.dateYearLong : formData.dateYear}
            </span>
          </div>
          <p className={styles.dayTime_text}>
            {isDateChanged ? formData.dateDayLong : formData.dateDay},{' '}
            {formData.placeTime}
          </p>
          <p className={styles.place_text}>{formData.place}</p>
        </div>
      </div>
    </div>
  );
}
export default Card;
