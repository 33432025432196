import styles from './style.module.css';

import ThankyouSecondImg from '../../../../assets/images/CardImages/CatThankYou/thankYou-2.png';

function Card({formData}) {
  return (
    <div
      className={styles.wrapper}
      style={{backgroundImage: `url(${ThankyouSecondImg})`}}
    >
      <div className={styles.inner}>
        <h2 className={styles.title}>Thank You</h2>
        <p className={styles.text}>for coming</p>
      </div>
    </div>
  );
}
export default Card;
