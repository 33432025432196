// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__ol7CF {
  width: 577px;
  height: 748px;
  box-shadow: 4px 4px 4px 0px #9D9D9D;
}
.style_inner__ju9RJ {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 180px 0px 120px;
  font-family: 'Carter One';
}
.style_top_text__qB8K1,
.style_name__tz7eg,
.style_age_block__Hd4mH,
.style_date_time__-vXcq,
.style_place_addr__2Yxl9,
.style_bottom_text__PKSWq {
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.style_top_text__qB8K1 {
  color: #e66d64;
  font-size: 20px;
}
.style_age_block__Hd4mH {
  color: #f9ca54;
  font-size: 48px;
}
.style_name__tz7eg {
  color: #2992b0;
  font-size: 75px;
}
.style_date_time__-vXcq {
  color: #a8d4aa;
  font-size: 24px;
}
.style_place_addr__2Yxl9 {
  color: #e66d64;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}
.style_bottom_text__PKSWq {
  color: #2992b0;
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CardsComponents/BirthdayCards/CatKids/AdventureParty/style.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mCAAmC;AACrC;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,8BAA8B;EAC9B,wBAAwB;EACxB,yBAAyB;AAC3B;AACA;;;;;;EAME,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,cAAc;EACd,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":[".wrapper {\n  width: 577px;\n  height: 748px;\n  box-shadow: 4px 4px 4px 0px #9D9D9D;\n}\n.inner {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  padding: 180px 0px 120px;\n  font-family: 'Carter One';\n}\n.top_text,\n.name,\n.age_block,\n.date_time,\n.place_addr,\n.bottom_text {\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  text-transform: uppercase;\n}\n.top_text {\n  color: #e66d64;\n  font-size: 20px;\n}\n.age_block {\n  color: #f9ca54;\n  font-size: 48px;\n}\n.name {\n  color: #2992b0;\n  font-size: 75px;\n}\n.date_time {\n  color: #a8d4aa;\n  font-size: 24px;\n}\n.place_addr {\n  color: #e66d64;\n  font-size: 16px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 40px;\n}\n.bottom_text {\n  color: #2992b0;\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__ol7CF`,
	"inner": `style_inner__ju9RJ`,
	"top_text": `style_top_text__qB8K1`,
	"name": `style_name__tz7eg`,
	"age_block": `style_age_block__Hd4mH`,
	"date_time": `style_date_time__-vXcq`,
	"place_addr": `style_place_addr__2Yxl9`,
	"bottom_text": `style_bottom_text__PKSWq`
};
export default ___CSS_LOADER_EXPORT___;
