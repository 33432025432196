import styles from './style.module.css'
import ButtonMore from '../ButtonMore/ButtonMore'


import Capture2 from '../../assets/images/GlobalImages/Capture-2.png'
import Capture3 from '../../assets/images/GlobalImages/Capture-3.png';
import Capture4 from '../../assets/images/GlobalImages/Capture-4.png';

const Invitations = () => {
    return (
      <section className={styles.section}>
        <div className="container">
          <div className={styles.wrapper}>
            <div className={styles.left_side}>
              <div className={styles.inner}>
                <h3 className={styles.title}>Wedding invites</h3>
                <ButtonMore path="/wedding" />
              </div>
              <div
                className={styles.capture3}
                style={{backgroundImage: `url(${Capture3})`}}
                alt="Capture3"
              ></div>
            </div>
            <div className={styles.mid_side}>
              <div
                className={styles.capture2}
                style={{backgroundImage: `url(${Capture2})`}}
                alt="Capture2"
              ></div>
              <div className={styles.inner}>
                <ButtonMore path="/birthday" />
                <h3 className={styles.title}>Birthday invites</h3>
              </div>
            </div>
            <div className={styles.right_side}>
              <div className={styles.inner}>
                <h3 className={styles.title}>Business invites</h3>
                <ButtonMore path="/business" />
              </div>
              <div
                className={styles.capture4}
                style={{backgroundImage: `url(${Capture4})`}}
                alt="Capture4"
              ></div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Invitations
