import styles from './style.module.css';
import {useNavigate} from 'react-router-dom';
import cards from '../../../data/cards';

import Search from '../../Search/Search';
import BreadCrambs from '../../BreadCrambs/BreadCrambs';

const MainWedding = () => {
  const navigate = useNavigate();
  let routes = ['Home', 'Card', 'Wedding',];

  return (
    <>
      <div className={styles.wrapper}>
        <Search />
        <div className="container">
          <BreadCrambs routes={routes} />
        </div>

        <div className={styles.inner}>
          <div className={styles.text_block}>
            <h2 className={styles.title}>Wedding invitation</h2>
            <p className={styles.subtitle}>
              Select your favorite wedding invite to share with all your guests.
            </p>
          </div>
        </div>

        <div className="container">
          <div className={styles.cards_block}>
            {cards
              .filter((item) => item.category === 'Wedding')
              .map((item) => {
                // Define styles conditionally
                const isWideCardsItems = item.wideWidth_yorn
                  ? {width: '610px'}
                  : {};
                return (
                  <div
                    key={item.id}
                    className={styles.card}
                    style={{...isWideCardsItems}}
                    onClick={() => navigate(`/card/${item.id}`)}
                  >
                    <div
                      className={styles.card_img}
                      style={{
                        backgroundImage: `url(${item.img})`,
                        ...isWideCardsItems,
                      }}
                    ></div>
                    <div className={styles.cards_info}>
                      <p>{item.name}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainWedding;
