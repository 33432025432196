import styles from './style.module.css';
import BlissImg from '../../../../../assets/images/CardImages/CatWedding/wedding/bliss.png';

function Card({formData, cardDetails, isDateChanged, isTimeChanged}) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <h2 className={styles.title}>
          <span>{formData.brideName}</span>
          <span>and</span> <span>{formData.groomName}</span>
        </h2>
        <img src={BlissImg} alt="" />
        <p className={styles.text}>ARE GETTING MARRIED</p>
        <div className={styles.mid}>
          <div className={styles.mid_left}>
            <span>
              {isDateChanged || cardDetails
                ? formData.dateDayLong
                : formData.dateDay}
            </span>
            <span>{formData.dateNumber}</span>
            <span>
              {isDateChanged || cardDetails
                ? formData.dateMonthLong
                : formData.dateMonth}
            </span>
            <span>
              AT{' '}
              {isTimeChanged || cardDetails
                ? formData.restaurantTime.split(':')[1] === '00'
                  ? formData.time12Hours
                  : formData.time12
                : formData.restaurantTime + ' PM'}
            </span>
          </div>
          <div className={styles.line}></div>
          <div className={styles.mid_right}>
            <p className={styles.mid_right_text}>
              At the {formData.restaurantName} Restaurant
            </p>
            <p className={styles.address_text}>{formData.restaurantAddress}</p>
          </div>
        </div>
        <div className={styles.bottom}>
          <p>WE WILL WAIT WITH LOVE</p>
        </div>
      </div>
    </div>
  );
}
export default Card;
