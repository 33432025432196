import {useNavigate, useParams} from 'react-router-dom';
import styles from './style.module.css';
import cards from '../../data/cards';
// Global Components ===================================
import Footer from '../../components/Footer/Footer';
// Images ============================================
import EditButton from '../../assets/images/GlobalImages/edit.png';
// Components
import componentMap from '../../data/componentsMap';

const CardPage = () => {
  const {id} = useParams();
  const cardItem = cards.find((item) => item.id === id);
  let cardDetails = null;
  if (localStorage.getItem('cardDetails')) {
    cardDetails = JSON.parse(localStorage.getItem('cardDetails'), true);
    if (cardDetails.id !== id) {
      localStorage.setItem('cardDetails', '');
    }
  }
  const formData = {
    id: cardDetails ? cardDetails.id : cardItem.id,
    defaultImg: cardDetails ? cardDetails.defaultImg : cardItem.defaultImg,
    groomName: cardDetails ? cardDetails.groomName : cardItem.groomName,
    brideName: cardDetails ? cardDetails.brideName : cardItem.brideName,
    groomSurname: cardDetails
      ? cardDetails.groomSurname
      : cardItem.groomSurname,
    brideSurname: cardDetails
      ? cardDetails.brideSurname
      : cardItem.brideSurname,
    fatherName: cardDetails ? cardDetails.fatherName : cardItem.fatherName,
    motherName: cardDetails ? cardDetails.motherName : cardItem.motherName,
    husbandName: cardDetails ? cardDetails.husbandName : cardItem.husbandName,
    wifeName: cardDetails ? cardDetails.wifeName : cardItem.wifeName,
    personName: cardDetails ? cardDetails.personName : cardItem.personName,
    age: cardDetails ? cardDetails.age : cardItem.age,
    churchName: cardDetails ? cardDetails.churchName : cardItem.churchName,
    churchAddress: cardDetails
      ? cardDetails.churchAddress
      : cardItem.churchAddress,
    churchTime: cardDetails ? cardDetails.churchTime : cardItem.churchTime,
    title: cardDetails ? cardDetails.title : cardItem.title,
    restaurantName: cardDetails
      ? cardDetails.restaurantName
      : cardItem.restaurantName,
    restaurantTime: cardDetails
      ? cardDetails.restaurantTime
      : cardItem.restaurantTime,
    restaurantAddress: cardDetails
      ? cardDetails.restaurantAddress
      : cardItem.restaurantAddress,
    address: cardDetails ? cardDetails.address : cardItem.address,
    addressTime: cardDetails ? cardDetails.addressTime : cardItem.addressTime,
    place: cardDetails ? cardDetails.place : cardItem.place,
    yearsAnniversary: cardDetails
      ? cardDetails.yearsAnniversary
      : cardItem.yearsAnniversary,
    placeTime: cardDetails ? cardDetails.placeTime : cardItem.placeTime,
    time: cardDetails ? cardDetails.time : cardItem.time,
    time12: cardDetails ? cardDetails.time12 : '',
    time24: cardDetails ? cardDetails.time24 : '',
    time12Hours: cardDetails ? cardDetails.time12Hours : '',
    time12Minutes: cardDetails ? cardDetails.time12Minutes : '',
    timePeriod: cardDetails ? cardDetails.timePeriod : '',
    date: cardDetails ? cardDetails.date : cardItem.date,
    dateDayLong: cardDetails ? cardDetails.dateDayLong : '',
    dateYearLong: cardDetails ? cardDetails.dateYearLong : '',
    dateMonthLong: cardDetails ? cardDetails.dateMonthLong : '',
    dateMonthShort: cardDetails ? cardDetails.dateMonthShort : '',
    dateMonthNumber: cardDetails ? cardDetails.dateMonthNumber : '',
    dateYearShort: cardDetails ? cardDetails.dateYearShort : '',
    dateDay: cardDetails ? cardDetails.dateDay : cardItem.dateDay,
    dateNumber: cardDetails ? cardDetails.dateNumber : cardItem.dateNumber,
    dateMonth: cardDetails ? cardDetails.dateMonth : cardItem.dateMonth,
    dateYear: cardDetails ? cardDetails.dateYear : cardItem.dateYear,
    email: cardDetails ? cardDetails.email : cardItem.email,
    phone: cardDetails ? cardDetails.phone : cardItem.phone,
    message: '',
    print: '',
    telephone: '',
  };

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/card/edit/${id}`);
  };
  const handleClickCont = () => {
    navigate(`/card/payment/${id}`);
  };

  const CardComponent = componentMap[cardItem.name];
  return (
    <div className={styles.content}>
      <div
        className={styles.wrapper}
        style={{backgroundImage: `url(${cardItem.bg})`}}
      >
        <CardComponent formData={formData} cardDetails={cardDetails} />
        {!cardItem.noEdit_yorn && (
          <div
            className={styles.edit_btn}
            style={{backgroundImage: `url(${EditButton})`}}
            onClick={handleClick}
          >
            Edit
          </div>
        )}
        {cardItem.noEdit_yorn && (
          <div
            className={styles.edit_btn}
            style={{backgroundImage: `url(${EditButton})`}}
            onClick={handleClickCont}
          >
            Continue
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default CardPage;
