import styles from './style.module.css';

import Capture1 from '../../assets/images/GlobalImages/Capture-1.png'
import ButtonMore from '../ButtonMore/ButtonMore';

const Celebrate = () => {
    return (
      <div className="container">
        <section className={styles.section}>
          <div className={styles.left_side}>
            <h2 className={styles.title}>Ready to celebrate?</h2>
            <p className={styles.subtitle}>
              Create your invitations for any occasion with us, generating
              online cards that you can also print and personalized invitation
              website link with all event details for your guests.
            </p>
            {/* <button className={styles.btn} type='button'>
                        <span>more</span>
                    </button> */}
            <ButtonMore forCelebrate={true} />
          </div>
          <div
            className={styles.capture1}
            alt="Capture1"
            style={{backgroundImage: `url(${Capture1})`}}
          ></div>
        </section>
      </div>
    );
}

export default Celebrate
