// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__rCIWD {
  width: 510px;
  height: 663px;
  box-shadow: 4px 4px 10px 5px #9a938d;
  font-family: 'Overlock SC';
}
.style_inner__vQgga {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 86px 0px 150px;
  justify-content: space-between;
  position: relative;
}
.style_inner__vQgga img {
  position: absolute;
  left: 0;
  top: 95px;
}
.style_date__mrkpi {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.style_name__x\\+g-E {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  margin-right: 118px;
  color: #ce9c1b;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.style_text__EIUj6 {
  max-width: 225px;
  text-align: center;
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
`, "",{"version":3,"sources":["webpack://./src/components/CardsComponents/PartyCards/CatGraduation/HatsOffToYou/style.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,oCAAoC;EACpC,0BAA0B;AAC5B;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,8BAA8B;EAC9B,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,OAAO;EACP,SAAS;AACX;AACA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,oBAAoB;EACpB,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".wrapper {\n  width: 510px;\n  height: 663px;\n  box-shadow: 4px 4px 10px 5px #9a938d;\n  font-family: 'Overlock SC';\n}\n.inner {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 86px 0px 150px;\n  justify-content: space-between;\n  position: relative;\n}\n.inner img {\n  position: absolute;\n  left: 0;\n  top: 95px;\n}\n.date {\n  color: #000;\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n\n.name {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  align-self: flex-end;\n  margin-right: 118px;\n  color: #ce9c1b;\n  font-size: 32px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  text-transform: uppercase;\n}\n.text {\n  max-width: 225px;\n  text-align: center;\n  color: #000;\n  text-align: center;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__rCIWD`,
	"inner": `style_inner__vQgga`,
	"date": `style_date__mrkpi`,
	"name": `style_name__x+g-E`,
	"text": `style_text__EIUj6`
};
export default ___CSS_LOADER_EXPORT___;
