import styles from './style.module.css';

import ThankyouThirdImg from '../../../../assets/images/CardImages/CatThankYou/thankYou-3.png';

function Card({formData}) {
  return (
    <div
      className={styles.wrapper}
      style={{backgroundImage: `url(${ThankyouThirdImg})`}}
    >
      <div className={styles.inner}>
        <h2 className={styles.title}>Thank You</h2>
      </div>
    </div>
  );
}
export default Card;
