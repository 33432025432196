import styles from './style.module.css';
import RoyalImg from '../../../../../assets/images/CardImages/CatWedding/wedding/royal.png';

function Card({formData, cardDetails, isDateChanged, isTimeChanged}) {
console.log(formData.time);
  return (
    <div
      className={styles.wrapper}
      style={{backgroundImage: `url(${RoyalImg})`}}
    >
      <div className={styles.inner}>
        <div className={styles.top}>
          <h2 className={styles.top_text}>THE WEDDING OF</h2>
        </div>
        <div className={styles.mid}>
          <span className={styles.groom_name}>{formData.groomName}</span>
          <span className={styles.text_and}>AND</span>
          <span className={styles.bride_name}>{formData.brideName}</span>
        </div>
        <div className={styles.bottom}>
          <p className={styles.month}>
            {isDateChanged || cardDetails
              ? formData.dateMonthLong
              : formData.dateMonth}
          </p>
          <div className={styles.bottom_mid}>
            <span className={styles.week}>
              {isDateChanged || cardDetails
                ? formData.dateDayLong
                : formData.dateDay}
            </span>
            <span className={styles.number}>{formData.dateNumber}</span>
            <p className={styles.time}>
              AT
              <span>
                {isTimeChanged || cardDetails
                  ? formData.time.split(':')[1] === '00'
                    ? formData.time12Hours
                    : formData.time12
                  : formData.time + ' PM'}
              </span>
            </p>
          </div>
          <span className={styles.year}>
            {isDateChanged || cardDetails
              ? formData.dateYearLong
              : formData.dateYear}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Card;
