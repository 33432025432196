// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_card_wrapper__G8LfH {
  width: 510px;
  height: 665px;
  text-align: center;
  background: #e1d1e4;
  box-shadow: 5px 5px 10px 0px #9a9a9a;
  padding: 50px;
  position: relative;
}
.style_inner__xjkyc {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 10px solid rgba(81, 17, 83, 0.76);
  background: #f5f5f5;
  padding: 80px 0;
}
.style_wrapper_top_img__hDZro,
.style_wrapper_bottom_img__OR\\+Kg {
  position: absolute;
}
.style_wrapper_top_img__hDZro {
  top: 0;
  left: 0;
}
.style_wrapper_bottom_img__OR\\+Kg {
  bottom: 0;
  left: 0;
}
.style_top__aDgem {
  display: flex;
  flex-direction: column;
  row-gap: 45px;
}
.style_text__FdiIw,
.style_name_text__LgSjN,
.style_title__3dfWn {
  color: #78487a;
  font-family: 'Jim Nightshade';
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 88.889% */
}
.style_title__3dfWn,
.style_text__FdiIw {
  font-size: 45px;
}
.style_name_text__LgSjN {
  font-size: 100px;
}
.style_date_text__xQcW4 {
  display: flex;
  justify-content: center;
  gap: 5px;
}
.style_bottom__wSCsM {
  color: #286760;
  font-family: 'Jim Nightshade';
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/components/CardsComponents/BirthdayCards/CatWooman/QueenOfLove/style.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,oCAAoC;EACpC,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,yCAAyC;EACzC,mBAAmB;EACnB,eAAe;AACjB;AACA;;EAEE,kBAAkB;AACpB;AACA;EACE,MAAM;EACN,OAAO;AACT;AACA;EACE,SAAS;EACT,OAAO;AACT;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;AACA;;;EAGE,cAAc;EACd,6BAA6B;EAC7B,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,YAAY;AACjC;AACA;;EAEE,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,QAAQ;AACV;AACA;EACE,cAAc;EACd,6BAA6B;EAC7B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".card_wrapper {\n  width: 510px;\n  height: 665px;\n  text-align: center;\n  background: #e1d1e4;\n  box-shadow: 5px 5px 10px 0px #9a9a9a;\n  padding: 50px;\n  position: relative;\n}\n.inner {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  border: 10px solid rgba(81, 17, 83, 0.76);\n  background: #f5f5f5;\n  padding: 80px 0;\n}\n.wrapper_top_img,\n.wrapper_bottom_img {\n  position: absolute;\n}\n.wrapper_top_img {\n  top: 0;\n  left: 0;\n}\n.wrapper_bottom_img {\n  bottom: 0;\n  left: 0;\n}\n.top {\n  display: flex;\n  flex-direction: column;\n  row-gap: 45px;\n}\n.text,\n.name_text,\n.title {\n  color: #78487a;\n  font-family: 'Jim Nightshade';\n  font-style: normal;\n  font-weight: 400;\n  line-height: 40px; /* 88.889% */\n}\n.title,\n.text {\n  font-size: 45px;\n}\n.name_text {\n  font-size: 100px;\n}\n.date_text {\n  display: flex;\n  justify-content: center;\n  gap: 5px;\n}\n.bottom {\n  color: #286760;\n  font-family: 'Jim Nightshade';\n  font-size: 26px;\n  font-style: normal;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card_wrapper": `style_card_wrapper__G8LfH`,
	"inner": `style_inner__xjkyc`,
	"wrapper_top_img": `style_wrapper_top_img__hDZro`,
	"wrapper_bottom_img": `style_wrapper_bottom_img__OR+Kg`,
	"top": `style_top__aDgem`,
	"text": `style_text__FdiIw`,
	"name_text": `style_name_text__LgSjN`,
	"title": `style_title__3dfWn`,
	"date_text": `style_date_text__xQcW4`,
	"bottom": `style_bottom__wSCsM`
};
export default ___CSS_LOADER_EXPORT___;
